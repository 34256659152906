import styled, { css } from 'styled-components'
import { Rem, Colors } from '../../commons/Theme'

export const StyledAccordion = styled.div`
  position: relative;
`

export const StyledAccordionHead = styled.div`
  cursor: pointer;
  position: relative;
`

export const StyledAccordionHeadIcon = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
`

export const StyledAccordionBody = styled.div`
  overflow: hidden;
  height: 0;

  ${(props) =>
    props.absolutePanel &&
    css`
      position: absolute;
      box-shadow: 0 0 4px 2px ${Colors.black};
      border-radius: ${Rem(10)};
    `}
`

export const StyledAccordionBodyContent = styled.div``
