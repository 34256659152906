export default {
  arrowLeft: 'ArrowLeft',
  arrowRight: 'ArrowRight',
  audio: 'Audio',
  bee: 'Bee',
  calendar: 'Calendar',
  checked: 'Checked',
  chart: 'Chart',
  checkOk: 'CheckOk',
  filtroRifiorisci: 'filtroRifiorisci',
  chevronDown: 'ChevronDown',
  chevronLeft: 'ChevronLeft',
  chevronRight: 'ChevronRight',
  clock: 'Clock',
  coins: 'Coins',
  cross: 'Cross',
  facebook: 'Facebook',
  globe: 'Globe',
  hexagon: 'Hexagon',
  hive: 'Hive',
  honey: 'Honey',
  honeydripper: 'Honeydripper',
  instagram: 'Instagram',
  libra: 'Libra',
  locker: 'Locker',
  man: 'Man',
  marker: 'Marker',
  message: 'Message',
  minus: 'Minus',
  phoneChat: 'PhoneChat',
  piggyBank: 'PiggyBank',
  play: 'Play',
  plus: 'Plus',
  report: 'Report',
  sensor: 'Sensor',
  sensors: 'Sensors',
  sun: 'Sun',
  triangleLeft: 'TriangleLeft',
  triangleRight: 'TriangleRight',
  twitter: 'Twitter',
  warn: 'Warn',
  wifi: 'Wifi',
  youtube: 'Youtube',
  shop0: 'Shop0',
  shop1: 'Shop1',
  shop2: 'Shop2',
  shopBee: 'ShopBee',
  shopCrown: 'ShopCrown',
  shopHoney: 'ShopHoney',
  check: 'Check',
  euFlag: 'EuropeanUnionFlag',
  italianFlag: 'ItalianFlag'
}
