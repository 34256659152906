import React from 'react'
import axios from 'axios'

const getPlants = (coupon) => {
  return axios
    .get(`${process.env.GATSBY_API_URL}honeys/?format=json&coupon=${coupon}`)
    .then((res) => res.data)
    .catch((err) => {
      return {
        error: true,
        message: err.message,
      }
    })
}

const getBeekeepers = (honeyID, coupon) => {
  const honeyParam = honeyID ? `&honey_id=${honeyID}` : ''
  const couponParam = honeyID ? `&coupon=${coupon}` : ''
  return axios
    .get(
      `${process.env.GATSBY_API_URL}beekeepers/?format=json${honeyParam}${couponParam}`
    )
    .then((res) =>
      res.data.map((x) => {
        return {
          ...x,
          slug: x.fullname
            ? x.fullname.toLowerCase().replace(/([ \.,'`"*_])/g, '-')
            : null,
        }
      })
    )
    .catch((err) => {
      return {
        error: true,
        message: err.message,
      }
    })
}

const getBeekeeper = (id, coupon) => {
  return axios
    .get(
      `${process.env.GATSBY_API_URL}beekeepers/${id}?format=json&coupon=${coupon}`
    )
    .then((res) => res.data)
    .catch((err) => {
      return {
        error: true,
        message: err.message,
      }
    })
}

const getAdoptionPlans = () => {
  return axios
    .get(`${process.env.GATSBY_API_URL}plans?format=json`)
    .then((res) => {
      return {
        data: res.data,
      }
    })
    .catch((err) => {
      return {
        error: true,
        message: err.message,
      }
    })
}

const getAdoptedHives = (purchaser, code) => {
  return axios
    .get(`https://uag8qn45g9.execute-api.eu-central-1.amazonaws.com/production/v1/hives?format=json&purchaser=${purchaser}&coupon=${code}`)
    .then((res) =>{
      return {
        ...res.data
      }}
      /*res.data.map((x) => {
        return {
          ...x,
          slug: x.fullname ? x.fullname.toLowerCase().replace(/ /g, '-') : null,
        }
      })*/
    )
    .catch((err) => {
      return {
        error: true,
        message: err.message,
      }
    })
}

const checkRedeemCode = (coupon) => {
  return (
    axios
      //.get(`https://096nakh653.execute-api.eu-central-1.amazonaws.com/dev/v1/coupon?code==${coupon}`)
      .get(
        `https://uqoe474go0.execute-api.eu-central-1.amazonaws.com/production/v1/coupon?format=json&code=${coupon}`
      )
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          return false
        }
        return {
          error: true,
          message: err.message,
        }
      })
  )
}


const postAdoption = (name_surname, email,hive_id, honey_id,beekeeper_id, coupon) => {
  return axios
    .post(
      `https://uqoe474go0.execute-api.eu-central-1.amazonaws.com/production/v1/adoption?format=json`,
      {
          fullname: name_surname,
          email: email,
          hive_id: hive_id,
          honey_id: honey_id,
          beekeeper_id: beekeeper_id,
          coupon: coupon
      }
    )
    .then((res) => {
      return {
        data: res,
      }
    })
    .catch((err) => {
      return {
        error: true,
        message: err.message,
      }
    })
}

let axiosConfig = { headers: { 'Content-Type': 'multipart/form-data' } };

const addNewsletterContact = (email,lang,typeOfList=6) => {
  const form_data = new FormData()
  form_data.append('email', email)
  form_data.append('lang', lang)
  form_data.append('list_id', typeOfList)
  return axios
    .post(
      `https://3bee.link:8989/api/addNewsletter`,
      form_data,
      axiosConfig,
    )
    .then((res) => {
      return {
        data: res,
      }
    })
    .catch((err) => {
      return {
        error: true,
        message: err.message,
      }
    })
}

const getHives = ({ honeyID, coupon }) => {
  const honeyParam = honeyID ? `&honey_id=${honeyID}` : ''
  const couponParam = coupon ? `&coupon=${coupon}` : ''
  return axios
    .get(
      `https://uag8qn45g9.execute-api.eu-central-1.amazonaws.com/production/v1/hives/?format=json${honeyParam}${couponParam}`
    )
    .then((res) =>
      Object.keys(res.data).map((key) => {
        return {
          items: res.data[key],
          fullname: key,
          slug: key.toLowerCase().replace(/([ \.,'`"*_])/g, '-'),
        }
      })
    )
    .catch((err) => {
      return {
        error: true,
        message: err.message,
      }
    })
}

const baseApiUrl = 'https://api.3bee.com/api/v1/';

const createUser = (userInfo, skipAddress) => {
  let payload = {
    email: userInfo.email,
    fullname: userInfo.fullname,
    locale: userInfo.locale,
  }
  if (!skipAddress) {
    payload["address"] = {
      street1: userInfo.street1,
      street_number: userInfo.street_number,
      zip: userInfo.zip,
      state: userInfo.state,
      country: userInfo.country,
      city: userInfo.city,
      force: userInfo.force
      //street2: userInfo.street2
    }
  }
  return axios
    .post(
      `${baseApiUrl}auth/users/create_user_hook/`,
      payload
    )
    .then((res) => {
      return {
        data: res,
      }
    })
    .catch((err) => {
      return {
        error: true,
        message: err.response.data,
      }
    })
}

const checkIfUserExist = (email) => {
  return axios
    .post(
      `${baseApiUrl}auth/users/check_user_email/`,
      {
        email: email,
      }
    )
    .then((res) => {
      return {
        data: res,
      }
    })
    .catch((err) => {
      return {
        error: true,
        message: err.response.data,
      }
    })
}

const loginUser = ({email, password}) => {
  return axios
    .post(
      `${baseApiUrl}auth/jwt/create/`,
      {
        email: email,
        password: password
      }
    )
    .then((res) => {
      return {
        data: res,
      }
    })
    .catch((err) => {
      return {
        error: true,
        message: err.response.data,
      }
    })
}


const getUserInfo = (accessToken) => {
  return axios
    .get(
      `${baseApiUrl}auth/users/me/`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
    .then((res) => {
      return {
        data: res,
      }
    })
    .catch((err) => {
      return {
        error: true,
        message: err.response.data,
      }
    })
}

const getPaymentToken = (accessToken) => {
  return axios
    .post(
      `${process.env.PAYMENT_BASE_URL}/generate-client-token`,
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
    .then((res) => {
      return {
        data: res,
      }
    })
}

const user3Bee = (email) => {
  return axios
    .post(
      `https://vezq863w58.execute-api.eu-central-1.amazonaws.com/production/v1/send_email_to_active_adopter/`,
      {
        email: email,
        template_id: 'd-50d0ab447d9f46a49519efa51a767e1a',
        template_id_else: 'd-8a001643b5b94b4291bca2c9b1c3709a',
      },
      {
        headers: {}
      }
    )
    .then((res) => {
      return {
        data: res,
      }
    })
    .catch((err) => {
      return {
        error: true,
        message: err,
      }
    })
}




const sendPaymentInfo = (accessToken, paymentInfo) => {
  let payload = {
    nonce: paymentInfo.nonce,
    deviceData: paymentInfo.deviceData,
    beekeeperId: paymentInfo.beekeeperId,
    hives: paymentInfo.hives,
    coupon: paymentInfo.coupon || null,
    bankTransfer: paymentInfo.bankTransfer,
    calculatedPrice: paymentInfo.calculatedPrice
  }
  return axios
    .post(
      `${process.env.PAYMENT_BASE_URL}/checkout`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
    .then((res) => {
      return {
        data: res,
      }
    })
}


const useApi = () => {
  return {
    getAdoptionPlans,
    getBeekeeper,
    getBeekeepers,
    getPlants,
    checkRedeemCode,
    postAdoption,
    addNewsletterContact,
    getAdoptedHives,
    getHives,
    createUser,
    loginUser,
    checkIfUserExist,
    getPaymentToken,
    sendPaymentInfo,
    getUserInfo,
    user3Bee,
  }
}

export default useApi
