import React from 'react'

export default ({ color }) => (
  <svg width="21" height="18" viewBox="0 0 21 18">
    <g clipPath="url(#clip-shop-crown)">
      <path
        d="M18.7809 4.49922C17.8379 4.49922 17.0735 5.17091 17.0735 5.99945C17.0739 6.15673 17.1027 6.31295 17.1589 6.46228L15.7742 7.23566C14.8625 7.74606 13.6605 7.5769 12.9758 6.84185L11.6185 5.3829C11.8345 5.12738 11.9513 4.81757 11.9514 4.49925C11.9514 3.67071 11.187 2.99902 10.244 2.99902C9.3011 2.99902 8.53668 3.67071 8.53668 4.49925C8.53684 4.81757 8.6536 5.12738 8.86961 5.3829L7.51226 6.84185C6.82771 7.57647 5.62627 7.7456 4.71478 7.23566L3.32934 6.46228C3.38556 6.31295 3.41437 6.15673 3.41469 5.99945C3.41485 5.17091 2.65059 4.49911 1.70761 4.49897C0.764708 4.49883 0.000160093 5.17042 2.84045e-08 5.99896C-0.000160036 6.77673 0.676192 7.42563 1.55795 7.49367L2.56699 12.8119C2.60121 12.9928 2.7792 13.1254 2.98784 13.1255H17.5004C17.709 13.1254 17.887 12.9928 17.9212 12.8119L18.9303 7.49367C19.8694 7.41965 20.5625 6.69065 20.4783 5.86542C20.3995 5.09295 19.6635 4.50066 18.7809 4.49922Z"
        fill="#FFC107"
      />
      <path
        d="M10.244 14.6255C14.2515 14.6255 17.5003 13.7859 17.5003 12.7503C17.5003 11.7146 14.2515 10.875 10.244 10.875C6.23652 10.875 2.98779 11.7146 2.98779 12.7503C2.98779 13.7859 6.23652 14.6255 10.244 14.6255Z"
        fill="#FFA000"
      />
      <path
        d="M10.2441 15.0002C6.42563 15.0002 2.56104 14.2276 2.56104 12.7498C2.56104 11.2721 6.42563 10.4995 10.2441 10.4995C14.0626 10.4995 17.9272 11.2721 17.9272 12.7498C17.9272 14.2276 14.0626 15.0002 10.2441 15.0002ZM10.2441 11.2496C5.7606 11.2496 3.4147 12.2247 3.4147 12.7498C3.4147 13.2749 5.7606 14.2501 10.2441 14.2501C14.7277 14.2501 17.0736 13.2749 17.0736 12.7498C17.0736 12.2247 14.7277 11.2496 10.2441 11.2496Z"
        fill="#FFD54F"
      />
    </g>
    <defs>
      <clipPath id="clip-shop-crown">
        <rect width="20.4852" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
