import React from 'react'
import * as SVG from './SVG'
import { StyledIcon } from './style'
export { default as icons } from './icons'

export default ({ color = 'black', icon, onClick }) => {
  const Icon = SVG[icon]
  return (
    <StyledIcon onClick={onClick}>
      <Icon color={color} />
    </StyledIcon>
  )
}
