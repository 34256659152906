import styled from 'styled-components'
import { BP, Rem } from './Theme'

export const StyledTitle = styled.h2`
  text-transform: uppercase;
  font-size: ${Rem(14)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(4)};
  padding-bottom: ${Rem(20)};

  @media (${BP.ipad}) {
    font-size: ${Rem(15)};
    letter-spacing: ${Rem(5)};
  }
`

export const StyledBigText = styled.h3`
  font-size: ${Rem(30)};
  line-height: ${Rem(37)};
  padding-bottom: ${Rem(30)};

  @media (${BP.ipad}) {
    font-size: ${Rem(50)};
    line-height: ${Rem(55)};
  }
`

export const StyledParagraph = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  padding-bottom: ${Rem(30)};

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
    line-height: ${Rem(30)};
  }
`
