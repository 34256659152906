import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
  ShadowBlack,
} from '../../commons/Theme'
import { StyledButton } from '../Button/style'
import { StyledFormInput, StyledFormSubmit } from '../../commons/Form'
import { StyledIcon } from '../Icon/style'

export const StyledFormRedeemCode = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.isValidCode &&
    css`
    ${StyledFormInput} {
      background-color: ${Colors.yellow};
      color: ${Colors.white};
      box-shadow:none;
      }
    `}

  ${StyledButton} {
    z-index: 2;
  }

  ${StyledFormInput} {
    ${Shadow};
    margin-bottom: ${Rem(24)};
  }

  ${StyledFormSubmit} {
    ${ShadowBlack};
    width: auto;
    min-width: 0;
    height: ${Rem(48)};
    margin: 0 auto;
    padding: 0 ${Rem(16)};

    ${StyledIcon} {
      margin-left: ${Rem(16)};
    }
  }
`

export const StyledReedemValidate = styled.div `
  padding-top: ${Rem(20)}
`

export const StyledReedemValidateText = styled.div `
  white-space: pre-wrap;
  padding:${Rem(4)};
  text-align: center;
  padding-bottom: ${Rem(10)}  ;
  margin: ${Rem(10)} ;
  word-
  font-size:${Rem(18)}; 
  line-height:${Rem(22)}; 
`
export const StyledFormRedeemCodeContent = styled.div`
  position: fixed;
  top: ${Rem(48)};
  ${ShadowBlack};
  width: 100vw;
  left: 0;
  height: auto;
  background-color: ${Colors.white};
  border-top: none;
  padding: ${Rem(24)} ${Rem(20)};
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transform: translate3d(0, ${Rem(20)}, 0);
  transition: all 0.25s ease-in-out 0s;

  @media (${BP.tablet}) {
    position: absolute;
    width: ${Rem(300)};
    top: calc(100% - ${Rem(2)});
    left: auto;
  }

  ${(props) =>
    props.isVisible &&
    css`
      opacity: 1;
      pointer-events: all;
      transform: translate3d(0, 0, 0);
    `}
`
export const StyledInvalidCodeParagraph = styled.p`
  font-size: ${Rem(13)};
  line-height: ${Rem(21)};
  padding-bottom: ${Rem(24)};
  color: ${Colors.red};

  @media (${BP.ipad}) {
    font-size: ${Rem(15)};
    line-height: ${Rem(27)};
  }
`