import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 30.7 22">
    <path
      fill={color}
      d="M27.5,4.9c-0.3-1.1-1.1-2-2.3-2.3c-1.9-0.5-9.9-0.5-9.9-0.5s-7.9,0-9.8,0.5c-1.1,0.3-2,1.1-2.3,2.3
	C2.7,6.8,2.7,11,2.7,11s0,4.1,0.5,6.1c0.3,1.1,1.1,1.9,2.3,2.3c2,0.5,9.9,0.5,9.9,0.5s7.9,0,9.9-0.5c1.1-0.3,1.9-1.1,2.3-2.3
	c0.5-2,0.5-6.1,0.5-6.1S28.1,6.9,27.5,4.9z M12.9,14.8V7.2l6.6,3.8L12.9,14.8z"
    />
  </svg>
)
