import styled, { css } from 'styled-components'
import { BP, Colors, Rem, Font } from '../../commons/Theme'
import { StyledButton } from '../Button/style'
import { StyledIcon } from '../Icon/style'
import { StyledGridRow } from '../../commons/Grid'
import styledMap from 'styled-map'

export const StyledHeader = styled.header`
  position: fixed;
  top: ${Rem(0)};
  left: 0;
  width: 100%;
  background-color: ${styledMap`
    yellow: ${Colors.yellow};
    black: ${Colors.black};
    default: ${Colors.white};
  `};
  z-index: 10;
`


export const StyledHeaderDiscount = styled.div`
  display: flex;  
  flex-wrap: wrap;
  justify-content: center;
  margin:0;
  padding:0;
  margin-top:${Rem(16)};
  @media (${BP.ipad}) {
    margin-top:${Rem(6)};
    justify-content: left;
  }
`

export const StyledHeaderDiscountText = styled.div`
  font-size: ${Rem(12)};
  text-align: center;
  @media (${BP.ipad}) {
    font-size: ${Rem(16)};
    text-align: left;
    padding-right: ${Rem(16)};
  }

`

export const StyledHeaderDiscountForm = styled.form`
  width:100%;
  @media (${BP.ipad}) {
    width:auto;
  }
`

export const StyledHeaderDiscountInput = styled.input`
  appearance: none;
  width:100%;
  font-size: ${Rem(14)};
  color: ${Colors.black};
  border: 1px solid ${Colors.yellow80};
  border-radius: 0;
  padding: ${Rem(6)} ${Rem(10)};
  font-family: ${Font.sans};
`

export const StyledHeaderDiscountSubmit = styled.button`
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${Rem(40)};
  border-radius: 0;
  border: none;
  margin-top:${Rem(5)};
  background-color: ${Colors.yellow};

  ${StyledIcon} {
    width: ${Rem(14)};
    top: auto;
    @media (${BP.ipad}) {
      display: inline-block;
    }
  }
`

export const StyledHeaderScript = styled.div`
  text-align: center;
  font-size: ${Rem(16)};
  padding-left: 0;
  padding-right: 0;
  @media (${BP.ipad}) {
    font-size: ${Rem(24)};
  }
`
export const StyledHeaderHiveIcon = styled.button`
  color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  border-style: none;
  ${StyledIcon} {
    width: ${Rem(36)};
  }

  ${(props) =>
    props.right &&
    css`
      position: absolute;
      right: 10px;
      @media (${BP.ipad}) {
        position: relative;
      }
    `}
`

export const StyledTitle = styled.div`
  display: inline;
  align-items: center;
`

export const StyledHeaderBlock = styled.div`
  padding: ${Rem(7)}; ${Rem(7)};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: auto;
  

  @media (${BP.ipad}){
    padding: ${Rem(14)}; ${Rem(14)};
    height: auto;
  } 

  ${(props) =>
    props.hasMenu &&
    css`
      width: 100%;
      max-width: ${Rem(900)};
    `}
  
  ${(props) =>
    props.latest &&
    css`
      padding-right: ${Rem(64)};

      @media (${BP.ipad}) {
        padding-right: ${Rem(16)};
      }
    `}

  ${StyledButton} {
    min-width: ${Rem(66)};
    padding:0 ${Rem(6)};
    height: ${Rem(32)};
    margin-right: ${Rem(16)};
    font-size: ${Rem(12)};
    

    @media (${BP.ipad}) {
        font-size: inherit;
      }

    + a ${StyledIcon} {
      text-decoration: none;
      width: ${Rem(32)};
      cursor: pointer;
    }
  }
  
  ${(props) =>
    props.theme.black &&
    css`
      ${StyledButton} {
        color: ${Colors.white};
        background-color: ${Colors.yellow};
      }
    `}
`
export const StyledHeaderRoutePage = styled.div`
  ${StyledButton} {
    box-shadow: 0 ${Rem(2)} ${Rem(4)} 0 ${Colors.black50};
  
    @media (${BP.ipad}) {
      box-shadow: 0 ${Rem(2)} ${Rem(4)} 0 ${Colors.black50};
    }

`
export const StyledHeaderLogo = styled.div`
    
   
`

export const StyledHeaderLogoImage = styled.img`
  height: ${Rem(38)};
`
