import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 35 30">
    <path
      fill={color}
      d="M24.4,25.1c0.3,1.3,0.6,2.6,0.9,3.9c0.1,0.2,0.1,0.3,0.1,0.6c0.2-0.2,0.3-0.5,0.6-0.7c-1.3,0-2.5,0-3.7,0
	c-0.2,0-0.3,0-0.5,0c0.2,0.2,0.3,0.3,0.6,0.4c-0.4-1-0.7-1.9-1.1-2.9c-0.1-0.3-0.4-0.5-0.7-0.4c-2.2,0.4-4.5,0.4-6.7,0
	c-0.3-0.1-0.6,0.1-0.7,0.4c-0.2,0.5-0.4,1-0.6,1.5c-0.2,0.5-0.4,1-0.6,1.5c0.2-0.2,0.3-0.3,0.6-0.4c-1.3,0-2.5,0-3.7,0
	c-0.2,0-0.3,0-0.5,0c0.2,0.2,0.3,0.5,0.6,0.7c0.3-1.3,0.6-2.6,0.9-3.8c0.1-0.2,0.1-0.3,0.1-0.6c0.1-0.2-0.1-0.5-0.3-0.6
	C9,24.3,8.5,24,8,23.8c-0.2-0.2-0.5-0.3-0.7-0.5c0.1,0.1-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.1C7,23,6.9,23,6.8,22.8
	c-0.4-0.3-0.8-0.7-1.1-1.1c-0.1-0.1-0.2-0.2-0.2-0.3c0,0-0.1-0.1-0.1-0.1c0.1,0.1,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.1
	c-0.2-0.2-0.3-0.4-0.5-0.6c-0.2-0.2-0.3-0.5-0.4-0.7c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1,0.1,0.1,0,0
	c0-0.1-0.1-0.1-0.1-0.1c-0.1-0.2-0.3-0.4-0.6-0.4c-0.6,0-1.3,0-1.9,0c-0.1,0-0.1,0-0.1,0c0.1,0-0.1,0-0.1-0.1
	c0.1,0.1-0.1-0.1-0.1-0.1c0.1,0.1-0.1-0.2-0.1-0.1l-0.1-0.1c-0.1-0.1,0,0.1,0,0c0-0.1,0-0.1,0-0.1c0-0.1,0,0.1,0,0
	c0-0.1,0-0.1,0-0.1c0-0.2,0-0.5,0-0.7c0-0.9,0-1.7,0-2.5c0-0.3,0-0.6,0-0.9c0-0.1,0-0.1,0-0.1c0-0.1,0-0.1,0-0.1c0,0.1,0,0,0,0
	s0.1-0.2,0-0.1c-0.1,0.1,0-0.1,0-0.1s0.1-0.1,0,0l0.1-0.1c0,0,0.1-0.1,0,0c0.1,0,0.1-0.1,0.1-0.1c-0.1,0.1,0.2-0.1,0.1,0
	c-0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0c0.2,0,0.4,0,0.6,0c0.3,0,0.7,0,1.1,0c0.3,0,0.5-0.2,0.6-0.4c0.2-0.6,0.6-1.2,0.9-1.7
	c0.2-0.3,0.3-0.5,0.6-0.7c0.1-0.2,0.2-0.3,0.2-0.6c0-0.6-0.1-1.2-0.1-1.8C5.6,7.8,5.6,7.3,5.5,6.8c0-0.1-0.1,0.1,0-0.1s0,0,0,0
	c0-0.1,0.1-0.1,0,0c0.1,0,0.1-0.1,0,0l0.1-0.1c-0.1,0.1-0.1,0,0,0c0,0-0.1,0,0,0H5.5c0,0,0.1,0.1,0.1,0c-0.1-0.1,0,0,0.1,0.1
	C5.8,6.8,6,7,6.2,7.1c0.5,0.3,1.1,0.7,1.6,1.1c0.2,0.1,0.4,0.1,0.6,0c1.1-0.7,2.2-1.2,3.4-1.5c-0.2-0.2-0.5-0.3-0.7-0.6
	c-0.1,1.4,0.3,2.9,1.1,4.1c0.2,0.2,0.4,0.3,0.6,0.3c2.9-0.9,6-0.9,9,0c0.3,0.1,0.5-0.1,0.6-0.3c0.8-1.1,1.2-2.5,1.1-4
	c-0.2,0.2-0.5,0.3-0.7,0.6c0.8,0.3,1.6,0.6,2.4,1.1c0.7,0.4,1.4,0.9,2,1.4c1.3,1.1,2.4,2.4,2.9,3.8c0.3,0.8,0.5,1.7,0.6,2.4
	c0.1,0.4,0.1,0.8,0,1.2c0,0.2,0,0.3-0.1,0.5c0,0.2,0-0.1,0,0.1c0,0.1,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.2,0.7-0.3,1
	c-0.1,0.2-0.1,0.3-0.2,0.5c0,0.1-0.1,0.1-0.1,0.2c0,0,0.1-0.1,0,0s-0.1,0.2-0.1,0.3c-0.2,0.3-0.3,0.6-0.5,1
	c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.1,0.2-0.2,0.2c0,0.1-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0c0,0.1-0.1,0.1-0.1,0.1
	c-0.6,0.7-1.1,1.3-1.8,1.8c-0.1,0.1-0.2,0.2-0.5,0.3c-0.2,0.1-0.4,0.3-0.6,0.4c-0.4,0.3-0.8,0.5-1.3,0.7c-0.3,0.2-0.3,0.5-0.2,0.8
	c0.2,0.3,0.5,0.3,0.8,0.2c2.9-1.5,5.5-4.1,6.3-7.3c0.5-1.7,0.4-3.5-0.2-5.2c-0.6-1.7-1.7-3.3-3-4.5C26.8,7.4,25,6.4,23,5.7
	c-0.3-0.1-0.7,0.2-0.7,0.6c0.1,1.2-0.2,2.3-1,3.3c0.2-0.1,0.5-0.2,0.6-0.3c-2.4-0.7-5-0.9-7.5-0.5C13.7,9,13,9.2,12.4,9.3
	c0.2,0.1,0.5,0.2,0.6,0.3c-0.7-1-1-2.2-1-3.4c0-0.3-0.4-0.7-0.7-0.6C10,6,8.7,6.6,7.6,7.3c0.2,0,0.4,0,0.6,0C7.8,7,7.4,6.8,7.1,6.5
	C6.8,6.4,6.6,6.2,6.3,6C6.1,5.9,6,5.8,5.8,5.7C5.3,5,4.6,5.4,4.4,6C4.3,6.2,4.3,6.4,4.3,6.6c0.1,0.6,0.1,1.2,0.1,1.8
	c0.1,0.5,0.1,1,0.1,1.5c0.1-0.1,0.1-0.3,0.2-0.4c-0.7,0.9-1.4,1.9-1.8,3.1c0.2-0.2,0.3-0.3,0.6-0.4c-0.3,0-0.7,0-1,0
	c-0.3,0-0.7,0-1,0C0.6,12.3,0,12.9,0,13.7c0,0.2,0,0.5,0,0.6c0,0.9,0,1.8,0,2.7c0,0.3,0,0.6,0,0.9c0,0.6,0.3,1.1,0.8,1.5
	c0.5,0.2,1,0.2,1.5,0.2c0.4,0,0.8,0,1.1,0c-0.2-0.2-0.3-0.3-0.6-0.4c0.9,2.2,2.4,3.9,4.3,5.3c0.6,0.4,1.1,0.7,1.8,1
	c-0.1-0.2-0.2-0.5-0.3-0.6c-0.3,1.3-0.6,2.6-0.9,3.8c-0.1,0.2-0.1,0.3-0.1,0.6C7.6,29.6,7.8,30,8.2,30c1.3,0,2.5,0,3.7,0
	c0.2,0,0.3,0,0.5,0c0.3,0,0.5-0.2,0.6-0.4c0.2-0.5,0.4-1,0.6-1.5c0.2-0.5,0.4-1,0.6-1.5c-0.2,0.1-0.5,0.3-0.7,0.4
	c2.4,0.5,4.9,0.5,7.3,0c-0.2-0.1-0.5-0.3-0.7-0.4c0.4,1,0.7,1.9,1.1,2.9c0.1,0.2,0.3,0.4,0.6,0.4c1.3,0,2.5,0,3.7,0
	c0.2,0,0.3,0,0.5,0c0.4,0,0.6-0.3,0.6-0.7c-0.3-1.3-0.6-2.6-0.9-3.9c-0.1-0.2-0.1-0.3-0.1-0.6c-0.1-0.3-0.4-0.5-0.7-0.4
	C24.5,24.5,24.4,24.8,24.4,25.1z M31.3,16.5c0.4,0,0.8,0,1.1-0.1c0.5-0.1,0.9-0.4,1.3-0.7c0.7-0.6,1.1-1.6,1.1-2.5
	c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.2-0.6,0.6c0,0.1,0,0.2,0,0.3s0-0.1,0,0.1c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.2-0.1,0.3
	c0,0.1-0.1,0.1-0.1,0.2c0.1-0.1,0,0,0,0c-0.1,0.1-0.1,0.2-0.1,0.2c0,0.1-0.1,0.1-0.1,0.1c0,0-0.1,0.2,0,0.1
	c-0.1,0.2-0.2,0.3-0.4,0.4c0,0-0.1,0.1-0.1,0.1c0.1-0.1-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0-0.1,0.1-0.1,0.1
	c-0.1,0.1,0.1,0,0,0c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1c0.1,0,0,0-0.1,0c-0.1,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0
	c-0.3,0-0.6,0.3-0.6,0.6C30.7,16.2,31,16.5,31.3,16.5z M22.4,6.2c0,1.1-0.3,2.1-1,3C21.6,9.1,21.8,9,22,8.9c-2.4-0.7-5-0.9-7.5-0.5
	c-0.7,0.1-1.4,0.3-2.1,0.5C12.6,9,12.9,9.1,13,9.2c-0.2-0.3-0.5-0.7-0.6-1c0,0,0,0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.1
	c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.1-0.4-0.2-0.6C12.1,6.8,12,6.4,12.1,6c0-0.1,0-0.2,0-0.3c0,0,0-0.2,0-0.1
	c0-0.2,0.1-0.3,0.1-0.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.1,0.1-0.1,0.1-0.2s0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0,0c0.1-0.3,0.3-0.6,0.5-0.9
	c0.1-0.1,0.1-0.1,0.1-0.2C13,3.3,13,3.3,13,3.2c0.1-0.1-0.1,0.1,0,0c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.3
	c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0,0.1-0.1,0.1-0.1c0.1-0.1-0.1,0,0,0c0.5-0.3,0.7-0.5,1-0.6c0.1-0.1,0.1-0.1,0.2-0.1
	c0.1,0,0.1-0.1,0.1-0.1c0,0-0.1,0.1,0,0c0.2-0.1,0.3-0.1,0.5-0.2c0.2-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.1,0
	c0.2,0-0.1,0,0.1,0c0.3-0.1,0.6-0.1,0.9-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0c-0.1,0,0,0,0,0c0.2,0,0.3,0.1,0.5,0.1
	c0.2,0.1,0.3,0.1,0.4,0.1c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.1,0,0.1,0.1c0.1,0.1-0.1-0.1,0.1,0c0.3,0.1,0.6,0.3,0.8,0.5
	C20.2,2,20.2,2,20.3,2.1l0.1,0.1c-0.1-0.1,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3C21.1,2.9,21.1,3,21.2,3
	c0.1,0.1-0.1-0.1,0,0c0,0.1,0.1,0.1,0.1,0.1c0.2,0.3,0.4,0.6,0.5,0.9c0,0,0.1,0.2,0.1,0.1c0-0.1,0.1,0.1,0.1,0.1
	C22,4.3,22,4.4,22,4.5c0.1,0.2,0.1,0.3,0.2,0.5c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.1,0,0.1c0,0.1,0-0.1,0,0.1
	C22.4,5.8,22.4,6,22.4,6.2c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.2,0.6-0.6c0-1.5-0.6-3-1.6-4.1c-0.9-1.1-2.3-1.8-3.7-2
	c-1.5-0.2-3,0.1-4.4,0.9c-1.2,0.8-2.2,2-2.6,3.4c-0.6,1.8-0.3,3.9,0.8,5.5c0.2,0.2,0.4,0.3,0.6,0.3c2.9-0.9,6-0.9,9,0
	c0.3,0.1,0.5-0.1,0.6-0.3c0.7-1,1.1-2.3,1.1-3.5c0-0.3-0.3-0.6-0.6-0.6C22.6,5.7,22.4,5.9,22.4,6.2z M12,10.3
	c2.6-0.9,5.5-1.1,8.2-0.6c0.8,0.2,1.6,0.3,2.3,0.6c0.3,0.1,0.6-0.1,0.7-0.4c0.1-0.3-0.1-0.6-0.4-0.7C20.1,8.2,17,8,14.1,8.6
	c-0.8,0.2-1.6,0.3-2.4,0.6c-0.3,0.1-0.5,0.4-0.4,0.7C11.3,10.2,11.7,10.4,12,10.3z"
    />
  </svg>
)
