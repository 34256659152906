import React, { useEffect, useState } from 'react'
import {
  StyledModal,
  StyledModalBG,
  StyledModalCloser,
  StyledModalContent,
} from './style'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
export default ({ isVisible, children, loader }) => {
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    setVisible(isVisible || !!children)
  }, [isVisible, children])

  useEffect(() => {
    document.body.classList.toggle('has-modal', visible)
  }, [visible])

  return (
    <StyledModal isVisible={visible} loader={loader}>
      <StyledModalBG onClick={() => setVisible(false)} />
      <StyledModalContent>
        <StyledModalCloser>
          <Icon
            icon={icons.cross}
            color={Colors.black}
            onClick={() => setVisible(false)}
          />
        </StyledModalCloser>
        {children}
      </StyledModalContent>
    </StyledModal>
  )
}
