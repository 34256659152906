import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 32 28">
    <path
      fill={color}
      d="M0,18.1C0,23.5,4.4,28,9.8,28c2.9,0,5.7-1.3,7.5-3.5l0.1-0.1h0.2c1.5,0.1,2.6,0.1,3,0.1c1.5,0,9-0.1,11.1-2
	c0.1-0.1,0.2-0.2,0.3-0.3V2.3C32,2.2,31.9,2,31.7,1.9C29.4,0.1,22.1,0,20.7,0c-1.5,0-8.7,0.1-11,1.9C9.6,2,9.5,2.2,9.3,2.3v6H9
	C3.9,8.7,0,13,0,18.1z M30.9,21.6l-0.2,0.1l-0.1-0.3l0.1,0.3h-0.1c-1,0.5-4.2,1.4-9.8,1.4c-0.6,0-1.3,0-1.8-0.1h-0.5l0.3-0.4
	c0.3-0.6,0.6-1.2,0.7-1.8l0.1-0.3h0.3c0.4,0,0.8,0,1.1,0c1.1,0,6.7-0.1,9.8-1.3l0.4-0.2L30.9,21.6L30.9,21.6z M19.6,17.1l-0.1-0.4
	h0.4c0.3,0,0.6,0,0.8,0c1.5,0,6.8-0.1,9.8-1.3l0.4-0.2v2.5h-0.3c-1.3,0.7-5,1.4-9.9,1.4c-0.3,0-0.5,0-0.8,0h-0.3v-0.3
	c0-0.3,0.1-0.5,0.1-0.8C19.7,17.8,19.6,17.4,19.6,17.1z M30.6,14c-1.3,0.7-5,1.4-10,1.4c-0.4,0-0.8,0-1.1,0h-0.3l-0.1-0.2
	c-0.1-0.5-0.4-1-0.6-1.5l-0.3-0.5h0.6c0.8,0,1.4,0.1,1.8,0.1c1.1,0,6.7-0.1,9.8-1.3l0.4-0.2V14H30.6z M30.9,10.6h-0.3
	c-1.3,0.7-5,1.4-9.9,1.4c-1.1,0-2.1-0.1-3.2-0.1h-0.1l-0.1-0.1c-0.5-0.6-1.1-1.1-1.8-1.7l-1-0.7l1.2,0.1c2.2,0.3,4.2,0.3,5,0.3
	c1.1,0,6.7-0.1,9.8-1.3l0.4-0.2L30.9,10.6L30.9,10.6z M30.6,7.1c-1.1,0.5-4.3,1.4-10,1.4c-5,0-8.6-0.7-10-1.4L10.5,7V6.7V4.6
	l0.4,0.2c3,1.2,8.6,1.3,9.8,1.3c1.1,0,6.7-0.1,9.8-1.3l0.4-0.2V7L30.6,7.1l-0.1-0.3L30.6,7.1z M10.7,2.7c1.2-0.7,4.9-1.5,10-1.5
	c5.1,0,8.8,0.8,10,1.5L31.2,3l-0.5,0.3c-1.2,0.7-4.9,1.5-10,1.5c-5.1,0-8.8-0.8-10-1.5l-0.5-0.2L10.7,2.7z M9.8,9.5
	c4.8,0,8.6,3.9,8.6,8.7c0,2.3-0.9,4.5-2.5,6.1c-1.7,1.6-3.8,2.5-6.1,2.5c-4.8,0-8.7-3.9-8.7-8.7C1.1,13.4,5.1,9.5,9.8,9.5z"
    />
  </svg>
)
