import styled, { css } from 'styled-components'
import { Rem, BP } from './Theme'

const maxWidth = 1248

export const StyledGrid = styled.div`
  position: relative;
  width: 100%;
`
/**
 * @props noMargin, isFull, isFullMobile
 */
export const StyledGridRow = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto ${Rem(64)};
  padding: 0 ${Rem(20)};
  padding-top:${Rem(80)};
  
  
  @media (${BP.ipad}) {
    margin: 0 auto ${Rem(145)};
    max-width: ${Rem(maxWidth)};
  }

  ${(props) => 
    props.noMargin &&
    css`
      margin-bottom: 0;

      @media (${BP.ipad}) {
        margin-bottom: 0;
      }
    `}
  
  ${(props) =>
    props.isFull &&
    css`
      max-width: 100%;
      padding: 0;
      padding-top:${Rem(80)};

      @media (${BP.ipad}) {
        max-width: 100%;
      }
    `}

  ${(props) =>
    props.isFullMobile &&
    css`
      max-width: 100%;
      padding: 0;
      padding-top:${Rem(80)};

      @media (${BP.ipad}) {
        max-width: ${Rem(maxWidth)};
        padding: 0 ${Rem(20)};
        padding-top:${Rem(80)};
      }
    `}
`
