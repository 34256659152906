import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 22 17">
    <path
      fill={color}
      d="M12.4,6.6l0.1-0.1l0,0L12.4,6.6z M15.6,11.5v3.2c0,0.2-0.1,0.4-0.3,0.5l0,0l-2.8,1.4c-0.1,0-0.1,0.1-0.2,0.1
	c-0.1,0-0.2,0-0.2-0.1l0,0l0,0c0,0,0,0,0,0l0,0l-2.6-1.3l-2.6,1.3c-0.1,0-0.1,0.1-0.2,0.1s-0.2,0-0.2-0.1l-2.8-1.4l0,0
	c-0.2-0.1-0.3-0.3-0.3-0.5v-2.9l-2.5-1.3l0,0l0.1-0.1l0,0l0,0l-0.1,0.1c-0.2-0.1-0.3-0.3-0.3-0.5V6.9c0-0.2,0.1-0.4,0.3-0.5v0
	l2.5-1.3V2.3c0-0.2,0.1-0.4,0.3-0.5l0,0L3.6,2l0,0L3.5,1.8l2.8-1.4c0.1-0.1,0.3-0.1,0.5,0l0,0l2.8,1.4L9.6,2l0,0l0.1-0.1l0,0
	c0.2,0.1,0.3,0.3,0.3,0.5v2.9l2.5,1.3l-0.1,0.1l0,0l0,0l0.1-0.1c0.2,0.1,0.3,0.3,0.3,0.5v2.9l2.5,1.3l0,0
	C15.5,11.2,15.6,11.3,15.6,11.5z M14.7,14.5L14.7,14.5L14.7,14.5L14.7,14.5z M9.8,14.5L9.8,14.5L9.8,14.5L9.8,14.5z M9.8,11.7
	L9.8,11.7L9.8,11.7L9.8,11.7z M14.7,11.7L14.7,11.7L14.7,11.7L14.7,11.7z M14.6,14.4v-2.6l-2.3-1.2l-2.3,1.2v2.6l2.3,1.2L14.6,14.4z
	 M11.9,9.9L11.9,9.9L11.9,9.9L11.9,9.9z M9.1,2.5L9.1,2.5L9.1,2.5L9.1,2.5z M11.9,7.1L11.9,7.1L11.9,7.1L11.9,7.1z M11.7,9.8V7.2
	L9.4,6L7.1,7.2v2.6L9.4,11L11.7,9.8z M8.9,14.4v-2.6l-2.3-1.2l-2.3,1.2v2.6l2.3,1.2L8.9,14.4z M4.1,11.7L4.1,11.7L4.1,11.7L4.1,11.7
	z M6.6,10.5L6.6,10.5L6.6,10.5L6.6,10.5L6.6,10.5z M6.1,9.8V7.2L3.8,6L1.4,7.2v2.6L3.8,11L6.1,9.8z M4.3,2.6v2.6l2.3,1.2l2.3-1.2
	V2.6L6.6,1.4L4.3,2.6z M9.1,5.3L9.1,5.3L9.1,5.3L9.1,5.3z M3.8,11.1L3.8,11.1L3.8,11.1L3.8,11.1L3.8,11.1z M6.6,15.7L6.6,15.7
	L6.6,15.7L6.6,15.7L6.6,15.7z M9.1,11.7L9.1,11.7L9.1,11.7L9.1,11.7z M9.4,11.1L9.4,11.1L9.4,11.1L9.4,11.1L9.4,11.1z M9.4,15.1
	L9.4,15.1L9.4,15.1L9.4,15.1L9.4,15.1z M6.7,0.5L6.7,0.5L6.7,0.5L6.7,0.5z M0.8,6.6L0.8,6.6L0.8,6.6L0.8,6.6z M12.1,16.5L12.1,16.5
	L12.1,16.5L12.1,16.5z M21.6,4.1v3.2c0,0.2-0.1,0.4-0.3,0.5l-0.1-0.1l0,0l0,0.1l0,0l-2.8,1.4h0L18.4,9l0,0l0.1,0.1
	c-0.1,0-0.1,0.1-0.2,0.1s-0.2,0-0.2-0.1l-2.8-1.4l0.1-0.1h0l0,0l-0.1,0.1l0,0c-0.2-0.1-0.3-0.3-0.3-0.5V4.1c0-0.2,0.1-0.4,0.3-0.5
	l0.1,0.1l0,0l-0.1-0.1l0,0L18,2.2c0.1-0.1,0.3-0.1,0.5,0l0,0l2.8,1.4l0,0l0,0.1l0,0l0.1-0.1C21.5,3.7,21.6,3.9,21.6,4.1z M20.6,6.9
	V4.4l-2.3-1.2l-2.3,1.2v2.6l2.3,1.2L20.6,6.9z M18.2,8.3L18.2,8.3L18.2,8.3L18.2,8.3z M18.4,2.3L18.4,2.3L18.4,2.3L18.4,2.3z"
    />
  </svg>
)
