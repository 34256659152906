import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import {
  StyledForm,
  StyledFormBlock,
  StyledFormSubmit,
} from '../../commons/Form'

export const StyledApiErrorForm = styled.div`
  max-width: ${Rem(600)};
  padding: ${Rem(60)} ${Rem(40)};

  ${StyledForm} {
    overflow: hidden;
    position: relative;
  }

  ${StyledFormBlock} {
    margin-bottom: ${Rem(40)};

    @media (${BP.ipad}) {
      width: 100%;
      padding-right: ${Rem(240)};
    }
  }

  ${StyledFormSubmit} {
    margin: 0 auto;
    height: ${Rem(66)};

    @media (${BP.ipad}) {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  ${(props) =>
    props.isFull &&
    css`
      max-width: 100%;

      ${StyledFormBlock} {
        @media (${BP.ipad}) {
          width: 50%;
          float: right;
          padding-right: ${Rem(240)};
        }
      }
    `}
`

//export const StyledApiErrorForm = styled.div``
