import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 16 14">
    <path
      fill={color}
      d="M7.8,0.4L3.6,3.6H1.2c-0.5,0-1,0.4-1,1v4.7c0,0.5,0.4,1,1,1h2.5l4.1,3.2c0.7,0.6,1.7,0.1,1.7-0.9V1.4
	C9.6,0.4,8.5-0.1,7.8,0.4z M15.6,7.4C15.8,7.4,16,7.2,16,7c0-0.3-0.2-0.4-0.4-0.4h-2.5c-0.2,0-0.4,0.2-0.4,0.4
	c0,0.3,0.2,0.4,0.4,0.4H15.6z M14,2c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.2-0.1s-0.2,0.1-0.2,0.1l-1.7,1.8
	c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0.1,0.3,0.1,0.3c0.2,0.2,0.4,0.2,0.6,0L14,2.3C13.9,2.2,14,2.1,14,2z M11.9,9.8
	c-0.1,0-0.2,0-0.2,0.1c-0.2,0.2-0.2,0.4,0,0.6l1.7,1.8c0.2,0.2,0.4,0.2,0.6,0C14,12.1,14,12,14,12c0-0.1-0.1-0.3-0.1-0.3l-1.7-1.8
	C12,9.8,11.9,9.8,11.9,9.8z"
    />
  </svg>
)
