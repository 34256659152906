import styled, { css } from 'styled-components'
import { BP, Colors, Rem } from '../../commons/Theme'

export const StyledHiveTechCrowdMenu = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  color: ${Colors.yellow};
  background-color: ${Colors.white};
  opacity: 0;

  &.hide {
    opacity: 0;
    top: -64px;
    transition: opacity 1s ease-out, top 0.1s ease-out 1s;
  }

  &.show {
    opacity: 1;
    transition: opacity 1s ease-out;
  }

  ${(props) => !props.isSectionMenu && css`
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1;
    padding-bottom: ${Rem(4)};
  `}
`
export const StyledHiveTechCrowdMenuItem = styled.div`
  position: relative;
  width: 100%;
  padding: 0 ${Rem(4)};
  font-size: ${Rem(16)};
  border-top: 1px solid ${Colors.yellow};
  cursor: pointer;

  color: ${(props) => props.isSelected ? Colors.yellow : Colors.black};

  width: auto;
  font-size: ${Rem(14)};
  border-top: 0;
`

export const StyledHiveTechCrowdMenuItemImage = styled.div`
  margin: 6px 0;
  width: 100%;
  height: 30px;
  background-image: url('${(props) => props.src}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`