import React, { useContext, useEffect, useState } from 'react'
import { DataContext } from '../contexts/DataContext'

const useTheme = () => {
  const { theme } = useContext(DataContext)
  const [themeColor, setThemeColor] = useState({})

  useEffect(() => {
    const isYellow = theme === 'yellow'
    const isBlack = theme === 'black'
    document.body.classList.toggle('theme-yellow', isYellow)
    document.body.classList.toggle('theme-black', isBlack)
    setThemeColor({
      yellow: isYellow,
      black: isBlack,
    })
  }, [theme])

  return themeColor
}

export default useTheme
