import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 31 32">
    <path
      fill={color}
      d="M27.8,11.4c0.2,0.2,0.5,0.3,0.8,0.3l1.4,0c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1l-1.4,0c-0.6,0-1.1,0.5-1.1,1.1
	C27.4,11,27.6,11.2,27.8,11.4z M27.3,26.5c0,0.3,0.1,0.6,0.3,0.8l1,1c0.4,0.4,1.1,0.4,1.5,0c0.4-0.4,0.4-1.1,0-1.5l-1-1
	c-0.4-0.4-1.1-0.4-1.5,0C27.4,26,27.3,26.2,27.3,26.5z M4,11.2c0.2-0.2,0.3-0.5,0.3-0.8l0-1.4c0-0.6-0.5-1.1-1.1-1.1
	C2.6,7.9,2.1,8.4,2.1,9l0,1.4c0,0.6,0.5,1.1,1.1,1.1C3.5,11.5,3.8,11.4,4,11.2z M3.2,25.8c0-0.3-0.1-0.6-0.3-0.8l-1-1
	c-0.4-0.4-1.1-0.4-1.5,0c-0.4,0.4-0.4,1.1,0,1.5l1,1c0.4,0.4,1.1,0.4,1.5,0C3,26.3,3.2,26,3.2,25.8z M23.6,3.2
	c0.3,0,0.6-0.1,0.8-0.3l1-1c0.4-0.4,0.4-1.1,0-1.5c-0.4-0.4-1.1-0.4-1.5,0l-1,1c-0.4,0.4-0.4,1.1,0,1.5C23.1,3.1,23.4,3.2,23.6,3.2z
	 M4.9,15.4c5.3-0.5,10.7-0.6,16-0.4l-0.2-0.2c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.6,0.3-0.8c0.4-0.4,1.1-0.4,1.5,0l1,1
	c0.2,0.2,0.3,0.5,0.3,0.9c1.1,0.1,2.3,0.2,3.4,0.3c0.9,0.1,1.4-0.5,1.4-1.4c0-1-0.7-1.7-1.5-1.9c-7-1.3-14.8-1.3-21.9,0
	C4.2,12.3,3.5,13,3.5,14C3.5,14.8,4,15.4,4.9,15.4z M22.3,29.3c-4.1,0.5-8.6,0.5-12.6,0c-0.7-0.1-1.1,0.4-0.9,1
	c1.4,1.9,5.3,1.6,7.2,1.6c1.9,0,5.8,0.2,7.2-1.6C23.3,29.7,22.9,29.2,22.3,29.3z M7.1,10.8c5.8-0.8,11.8-0.8,17.7,0
	c0.8,0.1,1.4-0.5,1.3-1.3c-0.2-0.9-0.9-1.5-1.7-1.7c-5.5-0.8-11.3-0.8-16.8,0C6.8,8,6,8.6,5.9,9.6C5.8,10.4,6.3,11,7.1,10.8z
	 M9.6,6.8c4.1-0.5,8.6-0.5,12.6,0c0.7,0.1,1.1-0.4,0.9-1c-1.4-1.9-5.4-1.6-7.2-1.6c-1.9,0-5.9-0.3-7.2,1.6C8.6,6.4,9,6.9,9.6,6.8z
	 M24.8,25.3c-3.6,0.5-7.2,0.7-10.8,0.6c0,0.1-0.1,0.2-0.2,0.2l-1,1c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3
	c-0.4-0.4-0.4-0.9-0.1-1.4c-1.4-0.1-2.7-0.2-4-0.4c-0.8-0.1-1.4,0.5-1.3,1.3c0.2,0.9,0.9,1.5,1.7,1.7c5.5,0.8,11.3,0.8,16.8,0
	c0.8-0.1,1.5-0.7,1.7-1.7C26.2,25.7,25.6,25.2,24.8,25.3z M28.1,16.5c-8-0.7-16.3-0.7-24.3,0c-0.8,0.1-1.2,0.8-1.2,1.6
	c0,0.8,0.4,1.5,1.2,1.6c8,0.7,16.3,0.7,24.3,0c0.8-0.1,1.2-0.8,1.2-1.6C29.3,17.3,28.9,16.5,28.1,16.5z M27,20.8
	c-7.3,0.7-14.8,0.7-22.1,0c-0.9-0.1-1.4,0.5-1.4,1.4c0,1,0.7,1.7,1.5,1.9c2.3,0.4,4.6,0.7,6.9,0.8l0.3-0.4c0.4-0.4,1.1-0.4,1.5,0
	c0.1,0.1,0.2,0.3,0.3,0.4c4.3,0.1,8.7-0.2,12.8-0.9c0.8-0.2,1.5-0.9,1.5-1.9C28.4,21.3,27.9,20.7,27,20.8z"
    />
  </svg>
)
