import React, { useState } from 'react'
import Modal from '../components/Modal/Modal'

const useModal = () => {
  const [content, setContent] = useState(null)

  return {
    setModalContent: setContent,
    Modal: <Modal isVisible={!!content}>{content}</Modal>,
  }
}

export default useModal
