import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'

export const StyledShareCorner = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  bottom: 0;
  left: 0;
  background-color: ${Colors.white};
  z-index: 101;
  box-shadow: 0 0 ${Rem(10)} 0 rgba(0, 0, 0, 0.25);

  @media (${BP.large}) {
    width: ${Rem(280)};
    height: ${Rem(280)};
    bottom: auto;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%) rotate(-45deg);
    transition: all 0.4s ease-in-out 0s;
  }

  ${(props) =>
    props.hasCode &&
    css`
      @media (${BP.large}) {
        top: ${Rem(-30)};
        left: ${Rem(-30)};
      }
    `}
`

export const StyledShareCornerContent = styled.div`
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: ${Rem(24)};
  line-height: ${Rem(30)};
  color: ${Colors.yellow};
  padding: ${Rem(10)} ${Rem(18)};

  @media (${BP.large}) {
    font-size: ${Rem(18)};
    padding: ${Rem(10)} ${Rem(36)};
  }
`

export const StyledShareCornerContentArrow = styled.div`
  width: 0;
  height: 0;
  margin: 0 auto ${Rem(6)};
  border-style: solid;
  border-width: 0 ${Rem(16)} ${Rem(20)} ${Rem(16)};
  border-color: transparent transparent ${Colors.yellow} transparent;

  @media (${BP.large}) {
    transform: rotate(180deg);
  }
`

export const StyledCloseX = styled.span`
  position: absolute;
  right: ${Rem(24)};
  bottom:   ${Rem(24)};
  font-size: ${Rem(18)};
  @media (${BP.large}) {
    transform: rotate(180deg);
    font-size: ${Rem(0)};
  }
`
