import React, { useContext, useEffect, useState } from 'react'
import {
  StyledHeader,
  StyledHeaderBlock,
  StyledHeaderLogo,
  StyledHeaderLogoImage,
  StyledHeaderDiscount,
  StyledHeaderDiscountText,
  StyledHeaderDiscountForm,
  StyledHeaderDiscountInput,
  StyledHeaderDiscountSubmit,
  StyledTitle,
  StyledHeaderHiveIcon,
  StyledHeaderRoutePage,
} from './style.jsx'
import { Link } from 'gatsby'
import { DataContext } from '../../contexts/DataContext'
import MainMenu from '../MainMenu/MainMenu'
import Hamburger from '../Hamburger/Hamburger'
import logoSrc from '../../../static/2020/svg/logo.svg'
import logoWhiteSrc from '../../../static/2020/svg/logo-white.svg'
import logoFullWhiteSrc from '../../../static/2020/svg/logo-fullwhite.svg'
import Button from '../Button/Button'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import { StyledGridRow } from '../../commons/Grid'
import useTheme from '../../hooks/useTheme'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import FormRedeemCode from '../FormRedeemCode/FormRedeemCode'
import { Rem } from '../../commons/Theme'
import { navigate } from 'gatsby'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import HiveTechCrowdMenu from '../HiveTechCrowdMenu/HiveTechCrowdMenu.jsx'
import useApi from '../../hooks/useApi'
import { toast } from 'react-toastify'

gsap.registerPlugin(ScrollToPlugin)

const Header = (props) => {
  const { menu, labels, path, i18nLink, showTopMenu } = useContext(DataContext)
  const themeColor = useTheme()
  const { user3Bee, addNewsletterContact } = useApi()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [hasContacts, setHasContacts] = useState(false)
  const [hasAdoption, setHasAdoption] = useState(false)
  const [menuType, setMenuType] = useState(false)
  const [coupon, setCoupon] = useState(false)
  const [hasBlankTargets, setHasBlankTargets] = useState(false)
  /*const [labelHeader, setLabelHeader] = useState(labels.CHOOSEFLOWER)
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);*/
  const [honeyDescription, setHoneyDescription] = useState(false)
  const [beekeepersDescription, setBeekeepersDescription] = useState(false)
  const [adoptionDescription, setAdoptionDescription] = useState(false)
  const [submitButton, setSubmitButton] = useState(false)
  const [discount, setDiscount] = useState(true)
  const logoImage = themeColor.yellow
    ? logoFullWhiteSrc
    : themeColor.black
      ? logoWhiteSrc
      : logoSrc

  if (!menu) {
    return null
  }

  function isInViewport(el) {
    const rect = el.getBoundingClientRect()
    return rect.top <= 200
  }

  useEffect(() => {
    if (document.querySelector('#contactForm')) {
      setHasAdoption(false)
      setHasContacts(true)
    } else {
      setHasAdoption(true)
    }
    if (document.querySelector('#causeslist')) {
      setHasAdoption(true)
    }

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const _coupon = urlParams.get('coupon')
    setCoupon(_coupon)

    setHoneyDescription(document.querySelector('#honeyDescription'))
    setBeekeepersDescription(document.querySelector('#beekeepersDescription'))
    setAdoptionDescription(document.querySelector('#adoptionDescription'))
  })


  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'gtm.load',
      originalLocation:
        document.location.protocol +
        '//' +
        document.location.hostname +
        document.location.pathname +
        document.location.search,
    })
    const paths = [
      'adotta-alveare',
      'biodiversity',
      'beekeeper',
      'crowd',
      'alveari',
      'apicoltori',
      'crowd',
    ]
    if (paths.indexOf(path) >= 0) {
      setHasBlankTargets(true)
    }

    switch (path) {
      default:
        setMenuType('crowd')
        document.body.classList.remove('has-relative-header')
        document.body.classList.add('has-fixed-nav')
        break
    }
  }, [path])

  return (
    <StyledHeader {...themeColor} menuType={menuType}>
      <StyledHeaderBlock>
        <StyledHeaderLogo>
          <StyledHeaderLogoImage src={logoImage} />
        </StyledHeaderLogo>
      </StyledHeaderBlock>
      {menuType === 'crowd' && (
        <HiveTechCrowdMenu />
      )}
    </StyledHeader>
  )
}

export default Header
