import React from 'react'
import { Colors } from '../../../commons/Theme'

export default ({ color }) => (
  <svg width="32" height="32" viewBox="0 0 32 32">
    <path
      d="M28.8499 18.7334L28.8499 18.7334C28.751 19.223 28.3859 19.5 28.0398 19.5H11.754H11.1549L11.2621 20.0894L11.6257 22.0894L11.7003 22.5H12.1176H27.0297C27.4992 22.5 27.9815 23.0317 27.8398 23.7334L27.5333 25.2507L27.4603 25.6119L27.7837 25.7885C28.6491 26.2611 29.2778 27.2824 29.2778 28.5C29.2778 30.2138 28.055 31.5 26.6667 31.5C25.2783 31.5 24.0556 30.2138 24.0556 28.5C24.0556 27.642 24.3692 26.8774 24.8605 26.336L25.619 25.5H24.4902H12.8431H11.7142L12.4728 26.336C12.9641 26.8774 13.2778 27.642 13.2778 28.5C13.2778 30.2138 12.055 31.5 10.6667 31.5C9.27832 31.5 8.05556 30.2138 8.05556 28.5C8.05556 27.3601 8.6076 26.3907 9.38999 25.8845L9.66998 25.7033L9.61032 25.3752L5.70766 3.91056L5.63301 3.5H5.21572H1.33333C0.926823 3.5 0.5 3.10929 0.5 2.5V1.5C0.5 0.890714 0.926823 0.5 1.33333 0.5H7.02939C7.38126 0.5 7.75251 0.78698 7.84373 1.2888L7.84373 1.28882L8.35295 4.08945L8.4276 4.5H8.84489H30.6661C31.1356 4.5 31.6179 5.0317 31.4762 5.73343L28.8499 18.7334Z"
      fill={Colors.white}
      stroke={color}
    />
  </svg>
)
