import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 15 18">
    <path
      fill={color}
      d="M4,0.2c-1.2,0-2.2,0.9-2.2,2.1v8.8l-0.1,0.1c-1.2,0.9-1.8,2.3-1.5,3.8c0.3,1.5,1.5,2.6,3.1,2.9c1.2,0.2,2.3-0.1,3.2-0.8
	c0.9-0.7,1.4-1.8,1.4-2.9c0-1.2-0.6-2.3-1.6-3l-0.1-0.1V2.4C6.3,1.2,5.3,0.2,4,0.2z M7.4,14.3c0,0.9-0.4,1.7-1,2.3
	c-0.7,0.6-1.5,1-2.4,1c-1.6,0-3-1.1-3.3-2.6C0.5,13.6,1,12.3,2,11.6l0.3-0.2v-9c0-0.9,0.8-1.7,1.7-1.7s1.8,0.8,1.8,1.7v9L6,11.6
	C6.9,12.2,7.4,13.2,7.4,14.3z M2,14.6c0.2,0.9,1.1,1.6,2.1,1.6c0.6,0,1.1-0.2,1.5-0.6c0.4-0.4,0.6-0.9,0.6-1.4
	c0-1.1-0.8-1.6-1.3-1.9c-0.1-0.1-0.2-0.2-0.3-0.2L4.5,12V5.7H3.6V12l-0.1,0.1c-0.1,0.1-0.2,0.2-0.4,0.3C2.6,12.7,1.7,13.3,2,14.6z
	 M9.2,4.7c0.7-1.6,1.8-3.4,2.3-4.1c0.4,0.7,1.5,2.5,2.3,4.1c0.4,0.8,0.7,1.6,0.7,2.1c0,0.2,0,0.3-0.1,0.5c-0.9,0.4-2,0.3-2.7-0.4
	c-0.8-0.8-2-1-3-0.6C8.8,5.6,9,5.1,9.2,4.7z M14.2,4.7c-0.9-2-2.5-4.4-2.5-4.6C11.6,0,11.5,0,11.4,0c0,0-0.1,0-0.1,0.1
	c-0.1,0.1-1.7,2.6-2.5,4.6C8.5,5.4,8.3,6,8.2,6.5v0c0,0.1,0,0.2,0,0.2c0,1.8,1.5,3.3,3.3,3.3c1.6,0,3-1.1,3.3-2.6v0
	c0.1-0.2,0.1-0.4,0.1-0.6C14.8,6.2,14.6,5.5,14.2,4.7z"
    />
  </svg>
)
