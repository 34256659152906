import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 24 23">
    <path
      fill={color}
      d="M23.7,22.1l-11-11L23,0.9l-0.7-0.8L12,10.4L1.7,0.1L1,0.9l10.3,10.2l-11,11L1,22.9l11-11l11,11L23.7,22.1z"
    />
  </svg>
)
