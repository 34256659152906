import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 36 36">
    <path
      fill={color}
      d="M18,35.6C8.3,35.6,0.4,27.7,0.4,18C0.4,8.3,8.3,0.4,18,0.4c9.7,0,17.6,7.9,17.6,17.6
	C35.6,27.7,27.7,35.6,18,35.6z M18,3.6C10.1,3.6,3.6,10.1,3.6,18S10.1,32.4,18,32.4S32.4,25.9,32.4,18S25.9,3.6,18,3.6z M15.2,26
	c-0.3,0-0.5-0.1-0.8-0.2c-0.5-0.3-0.8-0.8-0.8-1.4V11.6c0-0.6,0.3-1.1,0.8-1.4c0.5-0.3,1.2-0.2,1.6,0.1l9.6,6.4
	c0.4,0.3,0.7,0.8,0.7,1.3s-0.3,1-0.7,1.3l-9.6,6.4C15.8,25.9,15.5,26,15.2,26z M16.8,14.6v6.8l5.1-3.4L16.8,14.6z"
    />
  </svg>
)
