import styled, { css } from 'styled-components'
import { Colors, Font, Rem } from '../../commons/Theme'
import { StyledIcon } from '../Icon/style'

export const StyledButton = styled.button`
  position: relative;
  appearance: none;
  color: ${Colors.white};
  background-color: ${Colors.yellow};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${Rem(16)};
  margin: 0 auto;
  border: none;
  box-shadow: none;
  font-family: ${Font.sans};
  font-weight: bold;
  font-size: inherit;
  cursor: pointer;
  white-space: nowrap;

  ${(props) =>
    props.isHidden &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`

export const StyledCta = styled(StyledButton)`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  min-width: ${Rem(120)};
  height: ${Rem(64)};
  padding: 0 ${Rem(30)};
  font-size: ${Rem(20)};
  letter-spacing: 0.5px;
  color: ${Colors.black};
  background-color: ${Colors.grayLight};
  text-decoration: none;
  cursor: pointer;
  z-index: 2;
  overflow: hidden;

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: inherit;
    z-index: 1;
    position: relative;
    
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100vw;
      height: 100vh;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  ${StyledIcon} {
    position: absolute;
    top: 50%;
    right: ${Rem(-30)};
    transform: translateY(-50%);
    z-index: 2;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    height: calc(100% - ${Rem(32)});
    width: ${Rem(5)};
    top: 50%;
    left: ${Rem(20)};
    transform: translateY(-50%);
    background-color: ${Colors.yellow};
    transition: left .25s ease-out 0s;
    z-index: 2;
  }
  
  ${(props) =>
    props.revert &&
    css`
      ${StyledIcon} {
        right: auto;
        left: ${Rem(-30)};
        transform: translateY(-50%) rotate(-180deg);
      }

      &:before {
        left: auto;
        right: ${Rem(20)};
      }
    `}

  ${(props) =>
    props.noPadding &&
    css`
      min-width: ${Rem(150)};
      padding: 0 ${Rem(50)} 0 ${Rem(24)};

      &:before {
        left: 0;
      }
    `}
    
  ${(props) =>
    props.theme === 'yellow' &&
    css`
      color: ${Colors.white};
      background-color: ${Colors.yellow};

      &:before {
        background-color: ${Colors.black};
      }
    `}
  
  ${(props) =>
    props.theme === 'white' &&
    css`
      background-color: ${Colors.white};

      &:before {
        background-color: ${Colors.yellow};
      }
    `}

  ${(props) =>
    props.theme === 'transparent' &&
    css`
      color: ${Colors.white};
      background-color: ${Colors.transparent};
    `}
  
  ${(props) =>
    props.noLine &&
    css`
      padding: 0 ${Rem(24)};

      &:before {
        display: none;
      }
    `}

  ${(props) =>
    props.smaller &&
    css`
      height: ${Rem(48)};
    `}

  ${(props) =>
    props.isDisabled &&
    css`
      cursor: auto;
      background-color: rgb(170, 170, 170);
      pointer-events: none;
    `}
`
