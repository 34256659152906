import styled, { css } from 'styled-components'
import { BP, Colors, Font, Rem, Shadow, ShadowWhite } from './Theme'
import { StyledIcon } from '../components/Icon/style'

export const StyledForm = styled.form``

export const StyledFormFieldset = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

export const StyledFormBlock = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: ${Rem(8)};

  @media (${BP.ipad}) {
    width: calc((100% / 3) - ${Rem(20)});
    margin-bottom: ${Rem(16)};
  }

  &:first-child&:last-child {
    width: 100%;
  }
`

export const StyledFormInput = styled.input`
  appearance: none;
  display: block;
  width: 100%;
  border-radius: 0;
  font-family: ${Font.sans};
  border: 1px solid ${Colors.transparent};
  border-bottom-color: ${Colors.black};
  padding: ${Rem(22)} ${Rem(16)};
  font-size: ${Rem(16)};
  resize: none;

  ${(props) =>
    props.isBlack &&
    css`
      border-bottom-color: ${Colors.white50};
      color: ${Colors.black};
      -color: ${Colors.transparent};

      & + ${StyledFormLabel} {
        color: ${Colors.white};
      }
    `}

  ${(props) =>
    props.isYellow &&
    css`
      border-bottom-color: ${Colors.white};
      color: ${Colors.white};
      background-color: ${Colors.transparent};

      & + ${StyledFormLabel} {
        color: ${Colors.white};
        opacity: 0.8;
      }
    `}

  &:focus {
    ${Shadow};

    ${(props) =>
      props.isYellow &&
      css`
        ${ShadowWhite};
      `}
  }
`

export const StyledFormLabel = styled.label`
  position: absolute;
  top: ${Rem(26)};
  left: 0;
  color: ${Colors.black};
  opacity: 0.4;
  pointer-events: none;
  transition: all 0.15s ease-out 0s;

  ${(props) =>
    props.isHidden &&
    css`
      opacity: 0 !important;
    `}

  ${StyledFormInput}:focus + & {
    transform: translateX(${Rem(16)});
  }
`

export const StyledFormCouponDoneText = styled.h2`
  margin:${Rem(16)};
  padding:${Rem(16)};
  font-size:${Rem(24)};
  line-height: ${Rem(26)};
`

export const StyledFormSubmit = styled.button`
  position: relative;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${Rem(220)};
  max-width: ${Rem(287)};
  height: ${Rem(78)};
  padding: 0 ${Rem(44)};
  font-size: ${Rem(18)};
  font-family: ${Font.sans};
  letter-spacing: 0.5px;
  white-space: nowrap;
  color: ${Colors.white};
  background-color: ${Colors.yellow};
  text-decoration: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  z-index: 2;
  transition: filter 0.25s ease-in-out 0s;

  @media (${BP.ipad}) {
    font-size: ${Rem(20)};
    height: ${Rem(98)};
    padding: 0 ${Rem(36)};
  }

  ${StyledIcon} {
    width: ${Rem(10)};
    margin-top: ${Rem(3)};
    margin-left: ${Rem(48)};
  }

  &[disabled] {
    pointer-events: none;
    filter: grayscale(1);
  }
`

export const StyledFormSelect = styled.div`
  position: relative;

  &:after {
    display: block;
    position: absolute;
    content: '';
    width: ${Rem(15)};
    height: ${Rem(9)};
    top: 50%;
    right: ${Rem(16)};
    background-image: url('/2020/svg/icons/chevron-down.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transform: translateY(-50%);
    pointer-events: none;
  }

  > select {
    font-family: ${Font.sans};
    letter-spacing: ${Rem(5)};
    appearance: none;
    display: block;
    width: 100%;
    height: ${Rem(66)};
    border: 1px solid ${Colors.gray};
    padding: ${Rem(16)} ${Rem(32)} ${Rem(16)} ${Rem(16)};
    text-transform: uppercase;
  }
`

export const StyledFormToggle = styled.label`
  position: relative;
  display: inline-block;
  width: ${Rem(54)};
  height: ${Rem(26)};
  padding-left: ${Rem(4)};
  margin-top:${Rem(4)};
  transform: scale(0.8);
  @media (${BP.ipad}) {
    transform: scale(1);
  }

  > input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + span {
      background-color: ${Colors.yellow};
    }

    &:focus + span {
    }

    &:checked + span:before {
      transform: translateX(${Rem(28)});
      box-shadow: -1px 0 3px 0 rgba(0, 0, 0, 0.4);
    }
  }

  > span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: ${Rem(24)};

    &:before {
      position: absolute;
      content: '';
      height: ${Rem(24)};
      width: ${Rem(24)};
      left: ${Rem(1)};
      bottom: ${Rem(1)};
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
      box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.4);
    }
  }
`

export const StyledFormCheckboxInput = styled.input.attrs({ 
  type: "checkbox",
})`
  /* Hide the browser's default checkbox */
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
`;

export const StyledFormCheckboxCheckmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: ${Rem(18)};
  height: ${Rem(18)};
  border-radius: ${Rem(3)};
  border: 1px solid ${Colors.yellow};

  @media (${BP.ipad}) {
    width: ${Rem(28)};
    height: ${Rem(28)};
  }

  /* Create the checkmark/indicator */
  &:after {
    /* Hidden when not checked */
    content: "";
    position: absolute;
    display: none;

    /* Style the checkmark/indicator */
    left: ${Rem(4)};
    top: ${Rem(0)};
    width: ${Rem(5)};
    height: ${Rem(10)};
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

    @media (${BP.ipad}) {
      left: ${Rem(6)};
      top: ${Rem(2)};
      width: ${Rem(10)};
      height: ${Rem(15)};
    }
  }
`;

export const StyledFormCheckbox = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  height: ${Rem(18)};
  line-height: ${Rem(18)};
  padding-left: ${Rem(18 + 10)};
  margin: ${Rem(8)} 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (${BP.ipad}) {
    height: ${Rem(28)};
    line-height: ${Rem(28)};
    padding-left: ${Rem(28 + 10)};
  }

  /* On mouse-over, add a grey background color */
  &:hover ${StyledFormCheckboxInput} ~ ${StyledFormCheckboxCheckmark} {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a yellow background */
  & ${StyledFormCheckboxInput}:checked ~ ${StyledFormCheckboxCheckmark} {
    background-color: ${Colors.yellow};
  }

  /* Show the checkmark when checked */
  & ${StyledFormCheckboxInput}:checked ~ ${StyledFormCheckboxCheckmark}:after {
    display: block;
  }
`;
