import styled from 'styled-components'
import { Rem } from '../../commons/Theme'

export const StyledIcon = styled.span`
  position: relative;
  display: inline-block;
  width: ${Rem(16)};
  font-size: 0;
  line-height: 0;

  > svg {
    width: 100%;
  }
`
