import styled, { css } from 'styled-components'
import { Colors, Rem, BP } from '../../commons/Theme'

export const StyledHamburger = styled.button`
  position: absolute;
  display: inline-block;
  appearance: none;
  width: ${Rem(48)};
  height: ${Rem(48)};
  top: ${Rem(8)};
  right: ${Rem(6)};
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  overflow: hidden;
  cursor: pointer;
  z-index: 101;

  @media (${BP.ipad}) {
    display: none;
  }

  ${(props) =>
    props.isBlack &&
    css`
      ${StyledHamburgerLine} {
        background-color: ${Colors.white};
      }
    `}

  ${(props) =>
    props.isCross &&
    css`
      ${StyledHamburgerLine} {
        opacity: 0;
        background-color: ${Colors.white};
        &:first-child {
          opacity: 1;
          transform: translate(0%, -50%) rotate(-45deg);
        }

        &:last-child {
          opacity: 1;
          transform: translate(0%, -50%) rotate(45deg);
        }
      }
    `}
`

export const StyledHamburgerLine = styled.span`
  position: absolute;
  display: inline-block;
  width: ${Rem(24)};
  height: 2px;
  background-color: ${Colors.black};
  top: 50%;
  left: 50%;
  transform: translate(0%, -50%);
  transition: all 0.25s ease-out 0s;

  &:first-child {
    transform: translate(0%, ${Rem(-6)});
  }

  &:last-child {
    transform: translate(0%, ${Rem(4)});
  }
`
