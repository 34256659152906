import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 36 26">
    <path
      fill={color}
      d="M35.2,16L29.3,4.4V4.2h-0.1l-0.1-0.1l-0.1,0.1h-5.8C22.7,1.9,20.5,0,18,0c-2.5,0-4.7,1.8-5.2,4.2H6.9v0L0.8,16
	C0.3,17,0,18.1,0,19.2C0,23,3.2,26,6.9,26c3.7,0,6.9-3,6.9-6.8c0-1.1-0.3-2.1-0.8-3L8.1,6.6h4.7c0.6,2.3,2.6,4.1,5.2,4.1
	c2.6,0,4.7-1.7,5.2-4.1h4.7L23.1,16c-0.5,1-0.8,2.1-0.8,3.1c0,3.8,3.2,6.8,6.9,6.8c3.8,0,6.9-3,6.9-6.8C36,18.1,35.7,17,35.2,16z
	 M3.2,16.6l3.7-7.3l3.7,7.3H3.2z M25.4,16.6l3.7-7.3l3.7,7.3H25.4z"
    />
  </svg>
)
