import styled, { css } from 'styled-components'
import {
  BP,
  Colors,
  Rem,
  Ratio,
  Sizes,
  Font,
  Shadow,
} from '../../commons/Theme'
import { StyledAccordionBody } from '../Accordion/style'
import { StyledIcon } from '../Icon/style'

export const StyledFooter = styled.div`
  color: ${Colors.white};
  background-color: ${Colors.black};
  padding: ${Rem(8)} 0 ${Rem(150)};

  @media (${BP.ipad}) {
    padding: ${Rem(54)} 0 ${Rem(140)};
  }

  ${StyledAccordionBody} {
    @media (${BP.ipad}) {
      height: auto !important;
    }
  }
`

export const StyledFooterNav = styled.nav`
  display: flex;
  flex-wrap: wrap;

  @media (${BP.ipad}) {
    flex-wrap: nowrap;
  }
`

export const StyledFooterNavCategory = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (${BP.ipad}) {
    border-bottom: none;
  }

  ${StyledIcon} {
    width: ${Rem(14)};

    @media (${BP.ipad}) {
      display: none;
    }
  }
`

export const StyledFooterNavCategoryLabel = styled.span`
  display: inline-block;
  width: 100%;
  font-size: ${Rem(16)};
  padding: ${Rem(16)} 0;
`

export const StyledFooterNavCategoryItem = styled.div`
  display: inline-block;
  width: 100%;
  font-size: ${Rem(14)};
  padding: ${Rem(8)} 0;
  color: ${Colors.white50};
  text-decoration: none;

  &:last-child {
    padding-bottom: ${Rem(16)};
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`

export const StyledFooterPayments = styled(StyledFooterNavCategoryItem)``

export const StyledFooterPaymentsImages = styled.div`
  display: flex;
  margin-bottom: ${Rem(20)};

  @media (${BP.ipad}) {
    margin-bottom: ${Rem(24)};
  }
`

export const StyledFooterPaymentsImage = styled.img`
  margin-right: ${Rem(12)};
`

export const StyledFooterNewsletter = styled.div`
  margin-bottom: ${Rem(8)};
`

export const StyledFooterNewsletterText = styled.div`
  margin-bottom: ${Rem(8)};

  @media (${BP.ipad}) {
    color: ${Colors.white};
  }
`

export const StyledFooterNewsletterForm = styled.form`
  display: flex;
  align-items: stretch;
`

export const StyledFooterNewsletterInput = styled.input`
  appearance: none;
  width: 100%;
  font-size: ${Rem(14)};
  color: ${Colors.white};
  background-color: ${Colors.black};
  border: 1px solid ${Colors.grayLight};
  border-radius: 0;
  padding: ${Rem(6)} ${Rem(10)};
  font-family: ${Font.sans};
`

export const StyledFooterNewsletterSubmit = styled.button`
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${Rem(40)};
  min-height: 100%;
  border-radius: 0;
  border: none;
  background-color: ${Colors.grayLight};

  ${StyledIcon} {
    width: ${Rem(14)};
    top: auto;

    @media (${BP.ipad}) {
      display: inline-block;
    }
  }
`

export const StyledFooterExtra = styled.div`
  position: relative;

  @media (${BP.ipad}) {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
    justify-content: space-between;
    padding-top: ${Rem(72)};
  }

  &:after {
    content: '';
    display: none;
    position: absolute;
    width: 100%;
    height: 1px;
    top: 50%;
    left: 0;
    background-color: ${Colors.white50};
    transform: translateY(${Rem(36)});

    @media (${BP.ipad}) {
      display: block;
    }
  }
`

export const StyledFooterExtraBlock = styled.div`
  width: 100%;

  &:first-child {
    @media (${BP.ipad}) {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column-reverse;
    }
  }
`

export const StyledFooterExtraFundImage = styled.img`
  width: 100%;
  margin-bottom: ${Rem(20)};
  width: ${Rem(80)};

  @media (${BP.ipad}) {
    position: absolute;
    width: auto;
    top: 0;
    left: 0;
  }
`
export const StyledFooterExtraFundImage2 = styled.img`
  width: 100%;
  margin-bottom: ${Rem(20)};

  @media (${BP.ipad}) {
    position: absolute;
    width: auto;
    top: 0;
    left: ${Rem(100)};
  }
`

export const StyledFooterExtraLanguages = styled.div`
  display: flex;
  color: ${Colors.white50};
  font-size: ${Rem(14)};
  padding: ${Rem(20)} 0 ${Rem(24)};

  ${StyledIcon} {
    margin-right: ${Rem(20)};
  }

  @media (${BP.ipad}) {
    border-bottom: none;
  }
`

export const StyledFooterSocialList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${Rem(20)} 0 ${Rem(24)};
  margin-bottom: ${Rem(20)};

  @media (${BP.ipad}) {
    margin-bottom: 0;
    border-bottom: none;
  }
`

export const StyledFooterSocial = styled.div`
  ${StyledIcon} {
    width: ${Rem(30)};
  }
`

export const StyledFooterMission = styled.div`
  @media (${BP.ipad}) {
    display: flex;
    align-items: center;
    padding: ${Rem(10)} 0 ${Rem(11)};
    margin-bottom: ${Rem(16)};
  }
`

export const StyledFooterMissionLogo = styled.img`
  display: none;
  margin-right: ${Rem(20)};

  @media (${BP.ipad}) {
    display: inline-block;
  }
`

export const StyledFooterCopyright = styled.div`
  @media (${BP.ipad}) {
    display: flex;
    align-items: center;
  }
`

export const StyledFooterText = styled.span`
  display: inline-block;
  font-size: ${Rem(14)};
  line-height: ${Rem(20)};
  color: ${Colors.white50};
  margin-bottom: ${Rem(20)};
  width: 100%;

  @media (${BP.ipad}) {
    width: auto;
    margin-bottom: 0;
  }

  ${StyledFooterCopyright} & {
    @media (${BP.ipad}) {
      margin-right: ${Rem(40)};
    }
  }

  > br {
    @media (${BP.ipad}) {
      display: none;
    }
  }
`
