import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 30.7 22">
    <path
      fill={color}
      d="M19.5,20.2h-8.4c-2.8,0-5-2.3-5-5V6.8c0-2.8,2.3-5,5-5h8.4c2.8,0,5,2.3,5,5v8.4C24.5,17.9,22.3,20.2,19.5,20.2z
	 M11.2,3.5C9.3,3.5,7.8,5,7.8,6.8v8.4c0,1.8,1.5,3.3,3.3,3.3h8.4c1.8,0,3.3-1.5,3.3-3.3V6.8c0-1.8-1.5-3.3-3.3-3.3H11.2z M15.4,15.2
	c-1.1,0-2.2-0.4-2.9-1.3c-1.3-1.3-1.6-3.3-0.8-4.8s2.6-2.5,4.3-2.3c1.8,0.3,3.3,1.7,3.5,3.5l0,0c0.3,1.8-0.7,3.5-2.3,4.3
	C16.7,15,16,15.2,15.4,15.2z M15.4,8.5c-0.9,0-1.8,0.5-2.2,1.3c-0.5,1-0.3,2.2,0.5,2.9c0.8,0.8,1.9,0.9,2.9,0.5
	c1-0.5,1.5-1.5,1.3-2.6l0,0c-0.2-1.1-1-1.9-2.1-2.1C15.6,8.5,15.5,8.5,15.4,8.5z M19.9,7.2c-0.5,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8
	l0,0c0.5,0,0.8,0.3,0.8,0.8S20.5,7.2,19.9,7.2z"
    />
  </svg>
)
