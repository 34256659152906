import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 16 16">
    <path
      fill={color}
      d="M8,15.7c-4.2,0-7.7-3.5-7.7-7.7c0-4.2,3.5-7.7,7.7-7.7c4.2,0,7.7,3.5,7.7,7.7C15.7,12.2,12.2,15.7,8,15.7z
	 M11.5,8.7c-0.2,1.9-0.8,3.8-1.9,5.4c2.5-0.6,4.4-2.8,4.7-5.4H11.5z M1.7,8.7c0.3,2.6,2.2,4.7,4.7,5.4c-1.1-1.6-1.7-3.5-1.9-5.4H1.7
	z M5.9,8.7c0.2,1.9,0.9,3.7,2.1,5.2c1.2-1.5,1.9-3.3,2.1-5.2H5.9z M11.5,7.3h2.8C14,4.7,12.1,2.6,9.6,1.9
	C10.7,3.5,11.3,5.4,11.5,7.3z M5.9,7.3h4.1C9.9,5.4,9.2,3.6,8,2.1C6.8,3.6,6.1,5.4,5.9,7.3z M1.7,7.3h2.8c0.2-1.9,0.8-3.8,1.9-5.4
	C3.9,2.6,2,4.7,1.7,7.3z"
    />
  </svg>
)
