import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 9 8">
    <path
      fill={color}
      d="M9,4C9,4,9,4,9,4C9,4,9,4,9,4c0-0.1,0-0.2-0.1-0.3c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1l-2-3C6.5,0,5.9-0.1,5.4,0.2
	C5,0.5,4.9,1.1,5.2,1.6l1,1.4H1C0.4,3,0,3.4,0,4s0.4,1,1,1h5.1l-1,1.4C4.9,6.9,5,7.5,5.4,7.8C5.6,7.9,5.8,8,6,8
	c0.3,0,0.6-0.2,0.8-0.4l2-3c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0-0.1C9,4.2,9,4.1,9,4z"
    />
  </svg>
)
