import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 32 32">
    <path
      fill={color}
      d="M17.3,17.9c0,0.2-0.2,0.4-0.4,0.4H4.5c-0.2,0-0.5-0.2-0.5-0.4c0-0.2,0.2-0.5,0.5-0.5h12.3C17.1,17.5,17.3,17.7,17.3,17.9z
	 M16.8,20.3H4.5c-0.2,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h12.3c0.2,0,0.4-0.2,0.4-0.5C17.3,20.5,17.1,20.3,16.8,20.3z M10.7,23.1H4.5
	c-0.2,0-0.5,0.2-0.5,0.4c0,0.2,0.2,0.5,0.5,0.5h6.2c0.2,0,0.5-0.2,0.5-0.5S10.9,23.1,10.7,23.1z M28.7,11.8H16.4
	c-0.2,0-0.5,0.2-0.5,0.4c0,0.2,0.2,0.5,0.5,0.5h12.3c0.2,0,0.5-0.2,0.5-0.5C29.1,12,28.9,11.8,28.7,11.8z M28.7,14.6h-6.2
	c-0.2,0-0.5,0.2-0.5,0.5s0.2,0.4,0.5,0.4h6.2c0.2,0,0.5-0.2,0.5-0.4S28.9,14.6,28.7,14.6z M3.5,4h9C12.8,4,13,3.8,13,3.6
	c0-0.2-0.2-0.5-0.5-0.5h-9c-0.2,0-0.5,0.2-0.5,0.5C3.1,3.8,3.3,4,3.5,4z M3.5,6.3H8c0.2,0,0.5-0.2,0.5-0.5S8.3,5.4,8,5.4H3.5
	c-0.2,0-0.5,0.2-0.5,0.5S3.3,6.3,3.5,6.3z M20.8,17v7.3c0,1.7-1.4,3.1-3.1,3.1H8.6c-0.1,0-0.1,0-0.1,0.1l-4,3.8l0,0
	c-0.3,0.3-0.7,0.3-1,0.1c-0.3-0.2-0.5-0.5-0.4-0.9l0.1,0l0,0l0,0l-0.1,0l0.6-3.1c-1.6,0-3-1.4-3-3V17c0-1.7,1.4-3.1,3.1-3.1h14.1
	C19.4,14,20.8,15.3,20.8,17z M19.6,17c0-1-0.8-1.9-1.9-1.9H3.6c-1,0-1.9,0.8-1.9,1.9v7.3c0,1,0.8,1.9,1.9,1.9h0.2
	c0.6,0,1,0.5,0.9,1.1l-0.4,2.4l3.2-3.1c0.3-0.2,0.6-0.4,1-0.4h9.2c1,0,1.9-0.8,1.9-1.9V17z M4.1,30L4.1,30L4.1,30L4.1,30L4.1,30z
	 M3,30.4L3,30.4l0.1,0L3,30.4z M2.5,8.6h3.4c0,0,0,0,0,0l1.4,2.5c0.1,0.3,0.4,0.4,0.7,0.4c0.3,0,0.6-0.2,0.7-0.4l1.4-2.5
	c0,0,0,0,0,0h3.4c1,0,1.7-0.8,1.7-1.7V2.3c0-1-0.8-1.7-1.7-1.7h-11c-1,0-1.7,0.8-1.7,1.7v0.2c0,0.2,0.2,0.5,0.5,0.5
	c0.2,0,0.5-0.2,0.5-0.5V2.3c0-0.5,0.4-0.8,0.8-0.8h11c0.5,0,0.8,0.4,0.8,0.8v4.5c0,0.5-0.4,0.8-0.8,0.8h-3.4c-0.3,0-0.6,0.2-0.8,0.5
	L8,10.5L6.6,8.1C6.5,7.8,6.2,7.7,5.8,7.7H2.5C2,7.7,1.6,7.3,1.6,6.8V4.6c0-0.2-0.2-0.5-0.5-0.5c-0.2,0-0.5,0.2-0.5,0.5v2.2
	C0.7,7.8,1.5,8.6,2.5,8.6z M28.8,9H16.3c-1.4,0-2.6,1.2-2.6,2.6v0.8c0,0.2,0.2,0.5,0.5,0.5c0.2,0,0.5-0.2,0.5-0.5v-0.8
	c0-1,0.8-1.7,1.7-1.7h12.6c1,0,1.7,0.8,1.7,1.7V18c0,1-0.8,1.7-1.7,1.7h-0.2c-0.5,0-0.8,0.4-0.7,0.9l0.4,2.3l-3.1-2.9
	c-0.2-0.2-0.5-0.3-0.8-0.3h-2.1c-0.2,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h2.1c0.1,0,0.1,0,0.2,0.1l3.5,3.4c0.2,0.2,0.5,0.2,0.8,0.1
	c0.3-0.1,0.4-0.4,0.3-0.7l-0.5-2.8h0c1.4,0,2.6-1.2,2.6-2.6v-6.4C31.4,10.2,30.3,9,28.8,9z"
    />
  </svg>
)
