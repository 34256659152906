import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 71 23">
    <path
      fill={color}
      d="M70.4,10H5.7l7-7c0.6-0.6,0.6-1.5,0-2.1c-0.6-0.6-1.5-0.6-2.1,0L1,10.4C0.4,11,0.4,12,1,12.6l9.5,9.5
	c0.6,0.6,1.5,0.6,2.1,0c0.6-0.6,0.6-1.5,0-2.1l-7-7h64.7V10z"
    />
  </svg>
)
