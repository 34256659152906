import React, { useEffect, useRef, useState } from 'react'
import {
  StyledAccordion,
  StyledAccordionBody,
  StyledAccordionBodyContent,
  StyledAccordionHead,
  StyledAccordionHeadIcon,
} from './style.jsx'
import gsap from 'gsap'

const Accordion = ({
  icons,
  open,
  absolutePanel,
  head,
  body,
  handleToggle,
  onToggle,
  openOnOver,
}) => {
  const [isOpen, setIsOpen] = useState(open)
  const accordionBody = useRef()
  const accordion = useRef()

  const toggle = (value) => {
    const accordionState =
      typeof value === 'boolean' ? value : handleToggle ? !open : !isOpen
    handleToggle ? handleToggle(accordionState) : setIsOpen(accordionState)
  }
  const onMouseEnter = () => (openOnOver ? toggle(true) : {})
  const onMouseLeave = () => (openOnOver ? toggle(false) : {})

  if (handleToggle) {
    useEffect(() => {
      gsap.to(accordionBody.current, {
        duration: 0.4,
        height: open ? 'auto' : 0,
      })
      if (typeof onToggle === 'function') {
        onToggle(open)
      }
    }, [open])
  } else {
    useEffect(() => {
      gsap.to(accordionBody.current, {
        duration: 0.4,
        height: isOpen ? 'auto' : 0,
      })
      if (typeof onToggle === 'function') {
        onToggle(isOpen)
      }
    }, [isOpen])
  }

  const _isOpen = handleToggle ? open : isOpen

  return (
    <StyledAccordion
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={accordion}
    >
      <StyledAccordionHead onClick={toggle}>
        {head}
        {icons && (
          <StyledAccordionHeadIcon upside={_isOpen}>
            {_isOpen ? icons.open : icons.close}
          </StyledAccordionHeadIcon>
        )}
      </StyledAccordionHead>
      <StyledAccordionBody
        isOpen={_isOpen}
        ref={accordionBody}
        absolutePanel={absolutePanel}
      >
        <StyledAccordionBodyContent>{body}</StyledAccordionBodyContent>
      </StyledAccordionBody>
    </StyledAccordion>
  )
}

export default Accordion
