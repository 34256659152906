import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 37 37">
    <path
      fill={color}
      d="M18.5,0.1C8.3,0.1,0.1,8.3,0.1,18.5s8.3,18.4,18.4,18.4s18.4-8.3,18.4-18.4S28.7,0.1,18.5,0.1z M18.5,35.8
	C9,35.8,1.3,28.1,1.3,18.5C1.3,9,9,1.3,18.5,1.3c9.5,0,17.2,7.7,17.2,17.2C35.8,28.1,28.1,35.8,18.5,35.8z M17.9,4.4V3.6
	c0-0.3,0.2-0.6,0.6-0.6c0.3,0,0.6,0.2,0.6,0.6v0.8c0,0.3-0.2,0.6-0.6,0.6C18.2,5,17.9,4.7,17.9,4.4z M30.5,8.7
	c0.2,0.2,0.2,0.6,0,0.8L29.9,10c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.6,0-0.8l0.6-0.6
	C29.9,8.5,30.2,8.5,30.5,8.7z M33.9,18.5c0,0.3-0.2,0.6-0.6,0.6h-0.8c-0.3,0-0.6-0.2-0.6-0.6s0.2-0.6,0.6-0.6h0.8
	C33.7,17.9,33.9,18.2,33.9,18.5z M29,28.2c0.2,0.2,0.2,0.6,0,0.8c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.3-0.1-0.4-0.2l-0.6-0.6
	c-0.2-0.2-0.2-0.6,0-0.8c0.2-0.2,0.6-0.2,0.8,0L29,28.2z M19,32.5v0.8c0,0.3-0.2,0.6-0.6,0.6s-0.6-0.2-0.6-0.6v-0.8
	c0-0.3,0.2-0.6,0.6-0.6S19,32.2,19,32.5z M9.3,27.6c0.2,0.2,0.2,0.6,0,0.8L8.7,29c-0.1,0.1-0.3,0.2-0.4,0.2S8,29.1,7.9,29
	c-0.2-0.2-0.2-0.6,0-0.8l0.6-0.6C8.7,27.4,9.1,27.4,9.3,27.6z M5,18.4C5,18.7,4.7,19,4.4,19H3.6C3.3,19,3,18.7,3,18.4
	s0.2-0.6,0.6-0.6h0.8C4.7,17.9,5,18.1,5,18.4z M7.8,10L7.2,9.4C7,9.2,7,8.9,7.2,8.6c0.2-0.2,0.6-0.2,0.8,0l0.6,0.6
	c0.2,0.2,0.2,0.6,0,0.8c-0.1,0.1-0.3,0.2-0.4,0.2S7.9,10.1,7.8,10z M24.7,21.7c-0.1,0.2-0.3,0.3-0.5,0.3c-0.1,0-0.2,0-0.3-0.1
	l-4.5-2.3c-0.3,0.2-0.6,0.4-1,0.4c-0.6,0-1.2-0.4-1.4-0.9h-3.4c-0.3,0-0.6-0.2-0.6-0.6s0.2-0.6,0.6-0.6h3.4c0.2-0.4,0.4-0.7,0.8-0.8
	v-6.3c0-0.3,0.2-0.6,0.6-0.6s0.6,0.2,0.6,0.6v6.3c0.5,0.2,0.9,0.8,0.9,1.4c0,0,0,0.1,0,0.1l4.5,2.3C24.7,21.1,24.8,21.4,24.7,21.7z"
    />
  </svg>
)
