import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 8 18">
    <path
      fill={color}
      d="M1.5,17.4c-0.2,0-0.4-0.1-0.6-0.2c-0.5-0.3-0.6-1-0.3-1.5L5.3,9L0.6,2.2C0.3,1.8,0.4,1.1,0.9,0.8
	C1.3,0.5,2,0.6,2.3,1.1l5.1,7.4c0.2,0.4,0.2,0.8,0,1.2l-5.1,7.4C2.1,17.2,1.8,17.4,1.5,17.4z"
    />
  </svg>
)
