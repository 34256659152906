import React from 'react'

export default ({ color }) => (
  <svg width="36" height="36" viewBox="0 0 36 36">
    <path
      d="M18 2.3999C17.7305 2.3999 17.4597 2.49118 17.2383 2.67178L4.03828 13.4718C3.64588 13.7946 3.49757 14.3266 3.66797 14.803C3.83957 15.2806 4.2924 15.5999 4.8 15.5999H6V27.5999C6 28.9259 7.074 29.9999 8.4 29.9999H9.6V31.1999C9.59775 31.3589 9.62713 31.5168 9.68643 31.6644C9.74572 31.8119 9.83376 31.9462 9.94541 32.0595C10.0571 32.1727 10.1901 32.2626 10.3368 32.324C10.4835 32.3854 10.641 32.417 10.8 32.417C10.959 32.417 11.1165 32.3854 11.2632 32.324C11.4099 32.2626 11.5429 32.1727 11.6546 32.0595C11.7662 31.9462 11.8543 31.8119 11.9136 31.6644C11.9729 31.5168 12.0023 31.3589 12 31.1999V29.9999H24V31.1999C23.9978 31.3589 24.0271 31.5168 24.0864 31.6644C24.1457 31.8119 24.2338 31.9462 24.3454 32.0595C24.4571 32.1727 24.5901 32.2626 24.7368 32.324C24.8835 32.3854 25.041 32.417 25.2 32.417C25.359 32.417 25.5165 32.3854 25.6632 32.324C25.8099 32.2626 25.9429 32.1727 26.0546 32.0595C26.1662 31.9462 26.2543 31.8119 26.3136 31.6644C26.3729 31.5168 26.4023 31.3589 26.4 31.1999V29.9999H27.6C28.926 29.9999 30 28.9259 30 27.5999V15.5999H31.2C31.7076 15.5999 32.1593 15.2806 32.3297 14.803C32.5001 14.3254 32.3518 13.7934 31.9594 13.4718L18.7594 2.67178C18.5386 2.49118 18.2696 2.3999 18 2.3999ZM18 7.1999C18.6624 7.1999 19.2 7.7375 19.2 8.3999C19.2 9.0623 18.6624 9.5999 18 9.5999C17.3376 9.5999 16.8 9.0623 16.8 8.3999C16.8 7.7375 17.3376 7.1999 18 7.1999ZM10.8 14.3999H25.2C25.8624 14.3999 26.4 14.9363 26.4 15.5999C26.4 16.2635 25.8624 16.7999 25.2 16.7999H10.8C10.1376 16.7999 9.6 16.2635 9.6 15.5999C9.6 14.9363 10.1376 14.3999 10.8 14.3999ZM10.8 19.1999H25.2C25.8624 19.1999 26.4 19.7363 26.4 20.3999C26.4 21.0635 25.8624 21.5999 25.2 21.5999H10.8C10.1376 21.5999 9.6 21.0635 9.6 20.3999C9.6 19.7363 10.1376 19.1999 10.8 19.1999ZM10.8 23.9999H25.2C25.8624 23.9999 26.4 24.5363 26.4 25.1999C26.4 25.8635 25.8624 26.3999 25.2 26.3999H10.8C10.1376 26.3999 9.6 25.8635 9.6 25.1999C9.6 24.5363 10.1376 23.9999 10.8 23.9999Z"
      fill={color}
    />
  </svg>
)
