import React, { useContext, useEffect, useState } from 'react'
import {
  StyledFooter,
  StyledFooterNav,
  StyledFooterNavCategory,
  StyledFooterNavCategoryLabel,
  StyledFooterNavCategoryItem,
  StyledFooterPayments,
  StyledFooterPaymentsImage,
  StyledFooterPaymentsImages,
  StyledFooterNewsletter,
  StyledFooterNewsletterText,
  StyledFooterNewsletterForm,
  StyledFooterNewsletterInput,
  StyledFooterNewsletterSubmit,
  StyledFooterExtra,
  StyledFooterExtraBlock,
  StyledFooterExtraFundImage,
  StyledFooterExtraFundImage2,
  StyledFooterExtraLanguages,
  StyledFooterSocialList,
  StyledFooterSocial,
  StyledFooterMission,
  StyledFooterMissionLogo,
  StyledFooterCopyright,
  StyledFooterText,
} from './style.jsx'
import { DataContext } from '../../contexts/DataContext'
import { StyledGridRow } from '../../commons/Grid'
import Accordion from '../Accordion/Accordion'
import { Link } from 'gatsby'
import Icon, { icons } from '../Icon/Icon'
import { Colors } from '../../commons/Theme'
import paymentsImage from '../../../static/2020/images/footer/payments.png'
import sepaImage from '../../../static/2020/images/footer/sepa.png'
import euFundImage from '../../../static/2020/images/footer/eu-fund.png'
import campaniaFundImage from '../../../static/2020/images/footer/porfesr.png'
import logoImage from '../../../static/2020/svg/logo-white.svg'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default () => {
  const { menu, labels, seo, path, i18nLink } = useContext(DataContext)
  const [hasBlankTargets, setHasBlankTargets] = useState(false)

  if (!menu) {
    return null
  }

  useEffect(() => {
    const paths = ['adotta-aveare', 'biodiversity', 'beekeeper']
    if (paths.indexOf(path) >= 0) {
      setHasBlankTargets(true)
    }
  }, [path])

  return (
    <StyledFooter>
      <StyledGridRow noMargin>
        <StyledFooterNav>
          <StyledFooterNavCategory>
            <Accordion
              icons={{
                open: <Icon icon={icons.minus} color={Colors.white} />,
                close: <Icon icon={icons.plus} color={Colors.white} />,
              }}
              body={
                <StyledFooterPayments>
                  <StyledFooterNewsletter>
                    <StyledFooterNewsletterText>
                      {labels.NEWSLETTER_ABOUT}
                    </StyledFooterNewsletterText>
                    <StyledFooterNewsletterForm
                      Validate
                      onSubmit={(event) => {
                        event.preventDefault()
                        toast.configure()
                        const form_data = new FormData(event.currentTarget)
                        fetch('https://api.ipify.org/')
                          .then((response) => response.text())
                          .then((response) => {
                            form_data.append('g-recaptcha-response', 'token')
                            form_data.append('lang', 'IT')
                            fetch('https://3bee.link:8989/api/addNewsletter', {
                              method: 'POST',
                              body: form_data,
                            })
                              .then((response) => {
                                if (response.status === 200) {
                                  toast.success('Iscritto!', {
                                    containerId: 'Alert',
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                  })
                                } else {
                                  toast.error(
                                    'Errore, riprova o contatta info@3bee.it',
                                    {
                                      containerId: 'Alert',
                                      position: toast.POSITION.TOP_CENTER,
                                    }
                                  )
                                }
                              })
                              .catch(function () {
                                toast.error(
                                  'Errore, contattaci a info@3bee.it',
                                  {
                                    containerId: 'Alert',
                                    position: toast.POSITION.BOTTOM_CENTER,
                                  }
                                )
                              })
                          })
                        event.target.reset()
                        return false
                      }}
                    >
                      <StyledFooterNewsletterInput
                        type="email"
                        name="email"
                        placeholder={labels.MAIL_ADDRESS}
                      />
                      <StyledFooterNewsletterSubmit>
                        <Icon icon={icons.arrowRight} color={Colors.black} />
                      </StyledFooterNewsletterSubmit>
                    </StyledFooterNewsletterForm>
                  </StyledFooterNewsletter>
                </StyledFooterPayments>
              }
            />
          </StyledFooterNavCategory>
        </StyledFooterNav>
        <StyledFooterExtra>
          <StyledFooterExtraBlock>
            <StyledFooterSocialList>
              {seo.social.map((social, index) => (
                <StyledFooterSocial key={index}>
                  <a href={social.url} target="_blank">
                    <Icon
                      icon={icons[social.name.toLowerCase()]}
                      color={Colors.grayLight}
                    />
                  </a>
                </StyledFooterSocial>
              ))}
            </StyledFooterSocialList>
            <StyledFooterCopyright>
              <StyledFooterText>{labels.COPYRIGHT}</StyledFooterText>
              <StyledFooterText
                dangerouslySetInnerHTML={{ __html: labels.ADDRESS }}
              />
              <StyledFooterText>{labels.PIVA}</StyledFooterText>
            </StyledFooterCopyright>
          </StyledFooterExtraBlock>
        </StyledFooterExtra>
      </StyledGridRow>
    </StyledFooter>
  )
}
