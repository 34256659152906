import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 16 2">
    <path
      fill={color}
      d="M15,1.5H1C0.7,1.5,0.5,1.3,0.5,1S0.7,0.5,1,0.5h14c0.3,0,0.5,0.2,0.5,0.5S15.3,1.5,15,1.5z"
    />
  </svg>
)
