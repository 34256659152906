import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { BP, Colors, Rem } from '../../commons/Theme'
import { StyledButton } from '../Button/style'
import { StyledIcon } from '../Icon/style'
import {
  StyledAccordionBody,
  StyledAccordionHeadIcon,
} from '../Accordion/style'

export const StyledMainMenu = styled.nav`
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  top: 0;
  left: 0;
  color: ${Colors.white};
  background-color: ${Colors.black};
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.4s ease-out 0s;
  padding: ${Rem(8)} ${Rem(20)};
  overflow-x: hidden;
  z-index: 100;

  @media (${BP.ipad}) {
    position: relative;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    height: auto;
    transform: translate3d(0, 0, 0);
    color: ${styledMap`
      yellow: ${Colors.black};
      black: ${Colors.white};
      default: ${Colors.black};
    `};
    background-color: ${styledMap`
      yellow: ${Colors.yellow};
      black: ${Colors.black};
      default: ${Colors.white};
    `};
    overflow: visible;
    padding: ${Rem(8)} 0;
  }

  ${(props) =>
    props.isVisible &&
    css`
      transform: translate3d(0, 0, 0);
    `}
`

export const StyledMainMenuHead = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-right: ${Rem(44)};
  margin-bottom: ${Rem(16)};

  @media (${BP.ipad}) {
    display: none;
  }
`

export const StyledMainMenuLogo = styled.img``

export const StyledMainMenuContacts = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${StyledButton} {
    min-width: ${Rem(82)};
    height: ${Rem(32)};
    color: ${Colors.black};
    background-color: ${Colors.yellow};
  }
`

export const StyledMainMenuItem = styled.div`
  position: relative;
  width: 100%;
  padding: ${Rem(16)} 0;
  font-size: ${Rem(16)};
  padding: ${Rem(20)} 0;
  border-top: 1px solid ${Colors.white50};

  ${() => css`
    ${StyledMainMenuItem} & {
      font-size: ${Rem(14)};
      color: ${Colors.white50};
      border-top: 0;
      padding: ${Rem(8)} 0;

      @media (${BP.ipad}) {
        padding: ${Rem(16)};
        background-color: ${Colors.black};
      }

      &:first-child {
        padding-top: ${Rem(20)};
      }
    }
  `}

  @media (${BP.ipad}) {
    width: auto;
    font-size: ${Rem(14)};
    padding: ${Rem(16)} 0;
    border-top: 0;
  }

  > a {
    display: block;
    text-decoration: none;
    color: inherit;
    width: 100%;
    height: 100%;

    ${StyledIcon} {
      position: absolute;
      width: ${Rem(6)};
      pointer-events: none;
      top: 50%;
      right: ${Rem(4)};
      transform: translateY(-50%);
      
      @media (${BP.ipad}) {
        display: none;
      }
    }
  }
  
  ${StyledAccordionHeadIcon} {
    @media (${BP.ipad}) {
      display: none;
    }
  }
  
  ${StyledAccordionBody} {
    @media (${BP.ipad}) {
      position: absolute;
      padding-top: ${Rem(20)};
      width: 100%;
    }
  }
`

export const StyledMainMenuItemLink = styled.div`
  a {
    color: inherit;
    text-decoration: none;
  }
`
