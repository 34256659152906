import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 24 24">
    <path
      fill={color}
      d="M12,24c6.6,0,12-5.4,12-12c0-6.6-5.4-12-12-12C5.4,0,0,5.4,0,12C0,18.6,5.4,24,12,24z M12,1.3c5.9,0,10.7,4.8,10.7,10.7
	c0,5.9-4.8,10.7-10.7,10.7C6.1,22.7,1.3,17.9,1.3,12C1.3,6.1,6.1,1.3,12,1.3z M11.4,8.8v6.4c0,0.4,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6
	V8.8c0-0.4-0.3-0.6-0.6-0.6S11.4,8.5,11.4,8.8z M14.5,19.5c0.4,0,0.6-0.3,0.6-0.6V5.2c0-0.4-0.3-0.6-0.6-0.6c-0.4,0-0.6,0.3-0.6,0.6
	v13.7C13.9,19.2,14.2,19.5,14.5,19.5z M10.1,18.9V5.2c0-0.1,0-0.1,0-0.1c0-0.2-0.1-0.3-0.2-0.4C9.8,4.5,9.6,4.5,9.5,4.5
	c0,0,0,0-0.1,0C9.3,4.5,9.1,4.6,9,4.7C8.9,4.8,8.8,5,8.8,5.2v13.7c0,0.4,0.3,0.6,0.6,0.6C9.8,19.5,10.1,19.2,10.1,18.9z M6.9,17.7
	c0.4,0,0.6-0.3,0.6-0.6V7c0-0.4-0.3-0.6-0.6-0.6C6.6,6.4,6.3,6.6,6.3,7v10C6.3,17.4,6.6,17.7,6.9,17.7z M4.4,15.9
	c0.4,0,0.6-0.3,0.6-0.6V8.8c0-0.4-0.3-0.6-0.6-0.6c-0.4,0-0.6,0.3-0.6,0.6v6.4C3.8,15.6,4.1,15.9,4.4,15.9z M17.1,17.7
	c0.4,0,0.6-0.3,0.6-0.6V7c0-0.4-0.3-0.6-0.6-0.6c-0.4,0-0.6,0.3-0.6,0.6v10C16.4,17.4,16.7,17.7,17.1,17.7z M19.6,15.9
	c0.4,0,0.6-0.3,0.6-0.6V8.8c0-0.4-0.3-0.6-0.6-0.6c-0.4,0-0.6,0.3-0.6,0.6v6.4C19,15.6,19.2,15.9,19.6,15.9z"
    />
  </svg>
)
