import styled, { css } from "styled-components";
import { Colors, Rem } from "../../commons/Theme";

export const StyledSelect = styled.select`
  margin-left: ${Rem(16)};

  ${(props) =>
    props.isBlack &&
    css`
      background-color: ${Colors.black};
      color: ${Colors.white};
    `}
`