import React, { useEffect, useState } from 'react'
import {
  StyledShareCorner,
  StyledShareCornerContent,
  StyledShareCornerContentArrow,
  StyledCloseX,
} from './style'
import { FacebookShareButton } from 'react-share'
export default ({ url, title, hashtag, discountText, discountCode }) => {
  const [showCode, setShowCode] = useState(false)
  const [sharingCode, setSharingCode] = useState(true)

  useEffect(() => {
    const cookie = decodeURIComponent(document.cookie)
      .split(';')
      .find((x) => x.split('=')[0].replace(/ /g, '') === 'hasShareCode')
    if (cookie && cookie.split('=')[1] === 'true') {
        setShowCode(true)
    }

    const cookieClosed = decodeURIComponent(document.cookie)
      .split(';')
      .find((x) => x.split('=')[0].replace(/ /g, '') === 'closedSharingCode')
    if (cookieClosed && cookieClosed.split('=')[1] === 'true'){setSharingCode(false)}

    
    document.body.classList.add('has-share-corner')
    return () => {
      document.body.classList.remove('has-share-corner')
    }
  }, [])

  return (
    <StyledShareCorner>
      {sharingCode && (
      <>
        <StyledCloseX id='close' onClick = {() => {
              setSharingCode(false)
              document.cookie = `closedSharingCode=true; expires=${new Date(
                Date.now() + 1000 * 60 * 60 * 24 * 365
              )}; path=/`
              }}>
          x
        </StyledCloseX>
        {!showCode ? (
          <FacebookShareButton
            url={url}
            quote={title}
            hashtag={hashtag}
            beforeOnClick={() =>
              setTimeout(() => {
                setShowCode(true)
                document.cookie = `hasShareCode=true; expires=${new Date(
                  Date.now() + 1000 * 60 * 60 * 24 * 365
                )}; path=/`
              }, 10000)
            }
          >
            <StyledShareCornerContent>
              <StyledShareCornerContentArrow />
              {discountText}
            </StyledShareCornerContent>
          </FacebookShareButton>
        ) : (
          <StyledShareCornerContent>
            <StyledShareCornerContentArrow />
            {discountCode}
          </StyledShareCornerContent>
        )}
      </>
      )}
    </StyledShareCorner>
  )
}
