import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 37 37">
    <path
      fill={color}
      d="M36.9,33.1c0,0.3-0.2,0.6-0.5,0.7c-0.1,0-0.2,0.1-0.3,0.1H4.7v2.3c0,0.4-0.3,0.8-0.8,0.8c-0.1,0-0.2,0-0.3-0.1
	c-0.3-0.1-0.5-0.4-0.5-0.7v-2.3H0.8c-0.4,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8h2.3V0.8c0-0.4,0.3-0.8,0.8-0.8c0,0,0,0,0,0
	s0,0,0,0c0.4,0,0.8,0.3,0.8,0.8v31.5h31.5C36.6,32.3,36.9,32.7,36.9,33.1z M7.7,28.5V14.7c0-0.4,0.3-0.8,0.8-0.8h4.6
	c0.4,0,0.8,0.3,0.8,0.8v13.8c0,0.4-0.3,0.8-0.8,0.8H8.5C8.1,29.3,7.7,28.9,7.7,28.5z M9.3,27.7h3.1V15.4H9.3V27.7z M26.2,28.5V10.1
	c0-0.4,0.3-0.8,0.8-0.8h4.6c0.4,0,0.8,0.3,0.8,0.8v18.4c0,0.4-0.3,0.8-0.8,0.8h-4.6C26.5,29.3,26.2,28.9,26.2,28.5z M27.7,27.7h3.1
	V10.8h-3.1V27.7z M17,28.5V2.4c0-0.4,0.3-0.8,0.8-0.8h4.6c0.4,0,0.8,0.3,0.8,0.8v26.1c0,0.4-0.3,0.8-0.8,0.8h-4.6
	C17.3,29.3,17,28.9,17,28.5z M18.5,27.7h3.1V3.1h-3.1V27.7z"
    />
  </svg>
)
