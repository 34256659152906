import React, { useContext, useState } from 'react'
import { StyledApiErrorForm } from './style'
import { DataContext } from '../../contexts/DataContext'
import { LayoutContext } from '../../contexts/LayoutContext'
import {
  StyledForm,
  StyledFormBlock,
  StyledFormInput,
  StyledFormLabel,
  StyledFormSubmit,
} from '../../commons/Form'
import { StyledParagraph, StyledTitle } from '../../commons/Typography'
export default ({ title, paragraph, message, isFull }) => {
  const [formData, setFormData] = useState({
    email: '',
    error: message,
  })

  const { labels } = useContext(DataContext)
  const { setModalContent } = useContext(LayoutContext)
  return (
    <StyledApiErrorForm isFull={isFull}>
      <StyledTitle
        dangerouslySetInnerHTML={{
          __html: title || labels.API_FORM_ERROR_TITLE,
        }}
      />
      <StyledParagraph
        dangerouslySetInnerHTML={{
          __html: paragraph || labels.API_FORM_ERROR_DESCRIPTION,
        }}
      />
      <StyledForm
        noValidate
        onSubmit={(e) => {
          e.preventDefault()
          console.log(formData)
          setModalContent(false)
          return false
        }}
      >
        <StyledFormBlock>
          <StyledFormInput
            type="email"
            onInput={(e) =>
              setFormData({ ...formData, email: e.currentTarget.value })
            }
          />
          <StyledFormLabel
            isHidden={formData.email && formData.email.length > 0}
          >
            {labels.MAIL_ADDRESS}
          </StyledFormLabel>
        </StyledFormBlock>
        <StyledFormSubmit type="submit">
          {labels.API_FORM_CTA_LABEL}
        </StyledFormSubmit>
      </StyledForm>
    </StyledApiErrorForm>
  )
}
