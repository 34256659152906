import React, { useEffect, useState } from 'react'

export const DataContext = React.createContext({})

export const DataContextProvider = (props) => {
  const [data, setData] = useState(props.data)
  const [meta, setMeta] = useState()
  const [selectedSectionId, setSelectedSectionId] = useState(props.data == null ? null : props.data.menu.crowd[0].id)
  const [showTopMenu, setShowTopMenu] = useState(false)
  const [currentLanguageSuffix, setCurrentLanguageSuffix] = useState('')

  useEffect(() => {
    if (props.data == null) {
      return
    }
    let currentLanguage = props.data.menu.languages.find(
      (l) => l.isCurrent === true && l.isDefault === false
    )
    if (currentLanguage) {
      setCurrentLanguageSuffix(currentLanguage.baseUrl)
    }
    setData({ ...props.data, currentLanguage })
  }, [props.data])

  useEffect(() => {
    setData({ ...data, currentLanguageSuffix })
  }, [currentLanguageSuffix])

  useEffect(() => {
    if (meta) {
      setData({
        ...data,
        seo: { ...data.seo, meta: { ...data.seo.meta, ...meta } },
      })
    }
  }, [meta])

  return (
    <DataContext.Provider
      value={{
        ...data,
        setMeta,
        selectedSectionId,
        setSelectedSectionId,
        showTopMenu, 
        setShowTopMenu,
        path: props.path,
        currentLanguageSuffix: currentLanguageSuffix,
        languages: props.data == null ? null : props.data.menu.languages,
        i18nLink: (link) => {
          if (
            link.indexOf(currentLanguageSuffix) === 0 ||
            link.indexOf('http') === 0 ||
            link.indexOf('//') === 0
          ) {
            return link
          }
          return `${currentLanguageSuffix}${link}`
        },
      }}
    >
      {props.children}
    </DataContext.Provider>
  )
}
