import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 30.7 22">
    <path
      fill={color}
      d="M26.4,2c-1,0.7-2,1.2-3.1,1.5c-1.2-1.4-3.2-1.9-5-1.2c-1.8,0.7-2.9,2.4-2.9,4.2v1C11.8,7.7,8.4,6,6.4,3
	c0,0-4,9,5,13c-2.1,1.4-4.5,2.1-7,2c9,5,20,0,20-11.5c0-0.3,0-0.6-0.1-0.8C25.3,4.7,26,3.4,26.4,2z"
    />
  </svg>
)
