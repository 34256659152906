import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 34 28">
    <path
      fill={color}
      d="M19.1,25.5c0,1.2-1,2.1-2.1,2.1c-1.2,0-2.1-1-2.1-2.1c0-1.2,1-2.1,2.1-2.1C18.1,23.4,19.1,24.3,19.1,25.5z M33.2,7.4
	C29,2.9,23.2,0.4,17,0.4c-6.2,0-12,2.5-16.2,7.1C0.3,8,0.3,8.8,0.8,9.3c0.5,0.5,1.3,0.5,1.8-0.1C6.4,5.2,11.5,3,17,3
	c5.5,0,10.6,2.2,14.3,6.3c0.3,0.3,0.6,0.4,0.9,0.4c0.3,0,0.6-0.1,0.9-0.3C33.7,8.8,33.7,8,33.2,7.4z M17,7.9
	c-4.3,0-8.3,1.7-11.5,4.9c-0.5,0.5-0.5,1.3,0,1.8c0.5,0.5,1.3,0.5,1.8,0c2.7-2.7,6.1-4.2,9.6-4.2c3.6,0,7,1.5,9.6,4.2
	c0.3,0.3,0.6,0.4,0.9,0.4c0.3,0,0.7-0.1,0.9-0.4c0.5-0.5,0.5-1.3,0-1.8C25.3,9.6,21.3,7.9,17,7.9z M17,15.5C17,15.5,17,15.5,17,15.5
	L17,15.5C16.9,15.5,16.9,15.5,17,15.5c-2.3,0-4.7,1-6.4,2.7c-0.5,0.5-0.5,1.3,0,1.8c0.3,0.3,0.6,0.4,0.9,0.4c0.3,0,0.7-0.1,0.9-0.4
	c1.2-1.2,3-2,4.5-2c0,0,0,0,0,0H17c0,0,0,0,0,0c1.5,0,3.3,0.8,4.5,2c0.5,0.5,1.3,0.5,1.8,0c0.5-0.5,0.5-1.3,0-1.8
	C21.6,16.5,19.2,15.5,17,15.5z"
    />
  </svg>
)
