import React, { useEffect } from 'react'
import { StyledLayout } from './style'
import GlobalStyle from '../../commons/GlobalStyle'
import { DataContext, DataContextProvider } from '../../contexts/DataContext'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { LayoutContextProvider } from '../../contexts/LayoutContext'
import { loadReCaptcha } from 'react-recaptcha-v3'
import { StyledGrid, StyledGridRow } from '../../commons/Grid'
import { StyledLogo } from '../../commons/Theme'
import ShareCorner from '../ShareCorner/ShareCorner'

export default (props) => {
  /*useEffect(() => {
    loadReCaptcha(process.env.GATSBY_RECAPTCHA, () => {
      console.log('recaptcha loaded')
    })
  }, [])*/
  const isLanding = props.path === '/impactcall/'

  return (
    <div data-page={props.pageContext.originalPath}>
      <GlobalStyle />
      {isLanding ? (
        <StyledGrid>
          <StyledLayout>{props.children}</StyledLayout>
        </StyledGrid>
      ) : (
        <DataContextProvider
          {...props.pageContext}
          path={props.pageContext.originalPath}
        >
          <LayoutContextProvider>
            <StyledGrid>
              <Header />
              <DataContext.Consumer>
                {({ showShareCorner, labels }) =>
                  showShareCorner ? (
                    <ShareCorner
                      url={labels.SHARE_CORNER_URL}
                      discountText={labels.SHARE_CORNER_DISCOUNT_TEXT}
                      discountCode={labels.SHARE_CORNER_DISCOUNT_CODE}
                      title={labels.SHARE_CORNER_DISCOUNT_TITLE}
                      hashtag={labels.SHARE_CORNER_DISCOUNT_HASHTAG}
                    />
                  ) : null
                }
              </DataContext.Consumer>
              <StyledLayout>{props.children}</StyledLayout>
              <Footer />
            </StyledGrid>
          </LayoutContextProvider>
        </DataContextProvider>
      )}
    </div>
  )
}
