import React from 'react'
import { Colors } from '../../../commons/Theme'

export default ({ color }) => (
  <svg width="34" height="34" viewBox="0 0 34 34">
    <g clipPath="url(#clip-shop-1)">
      <path
        d="M19.2922 4.28953V2.74473H30.6163C31.0427 2.74473 31.3888 3.09046 31.3888 3.51713C31.3888 3.94394 31.0432 4.28953 30.6164 4.28953H19.2922Z"
        stroke={color}
        fill={Colors.white}
        strokeWidth="0.8"
      />
      <path
        d="M24.7545 15.6417H4.82346C4.07308 15.6417 3.46489 16.2499 3.46482 17.0003L24.7545 15.6417ZM24.7545 15.6417C25.5049 15.6417 26.1131 16.2499 26.1131 17.0003M24.7545 15.6417L26.1131 17.0003M26.1131 17.0003C26.1131 17.7508 25.505 18.359 24.7545 18.359M26.1131 17.0003L24.7545 18.359M24.7545 18.359H4.82346M24.7545 18.359H4.82346M4.82346 18.359C4.07305 18.359 3.46483 17.7508 3.46482 17.0004L4.82346 18.359Z"
        stroke={color}
        fill={Colors.white}
        strokeWidth="0.8"
      />
      <path
        d="M25.687 30.6322L23.9394 33.6006H5.6382L3.89068 30.6322C3.89067 30.6322 3.89066 30.6322 3.89065 30.6322C1.6845 26.8836 2.38871 22.1115 5.56481 19.1593H24.0128C27.1889 22.1115 27.8931 26.8836 25.687 30.6322C25.687 30.6322 25.687 30.6322 25.687 30.6322Z"
        stroke={color}
        fill={Colors.white}
        strokeWidth="0.8"
      />
      <path
        d="M15.961 0.399756H13.6162C11.8944 0.399756 10.4989 1.79522 10.4989 3.51703L15.961 0.399756ZM15.961 0.399756C17.6828 0.399756 19.0782 1.79523 19.0782 3.51703M15.961 0.399756L19.0782 3.51703M19.0782 3.51703C19.0782 5.23883 17.6828 6.6343 15.961 6.6343M19.0782 3.51703L15.961 6.6343M15.961 6.6343H13.6162M15.961 6.6343H13.6162M13.6162 6.6343C11.8944 6.6343 10.4989 5.23884 10.4989 3.51704L13.6162 6.6343Z"
        stroke={color}
        fill={Colors.white}
        strokeWidth="0.8"
      />
      <path
        d="M2.85776 23.8485H26.7197C27.2581 26.1545 26.8909 28.584 25.6869 30.6318C25.6869 30.6319 25.6869 30.6319 25.6868 30.632L23.9394 33.6002H5.63812L3.89069 30.632C2.68654 28.5841 2.31929 26.1546 2.85776 23.8485Z"
        stroke={color}
        fill={Colors.white}
        strokeWidth="0.8"
      />
      <path
        d="M14.6037 6.63448V0.4H14.9761V6.63448H14.6037Z"
        stroke={color}
        fill={Colors.white}
        strokeWidth="0.8"
      />
      <path
        d="M12.6302 6.47148C12.5029 6.42917 12.3785 6.37863 12.2578 6.32011V0.714409C12.3785 0.655835 12.5029 0.605264 12.6302 0.562949V6.47148Z"
        stroke={color}
        fill={Colors.white}
        strokeWidth="0.8"
      />
      <path
        d="M16.9475 6.47142V0.563082C17.0747 0.605395 17.199 0.655961 17.3199 0.714533V6.32004C17.1991 6.37857 17.0747 6.42911 16.9475 6.47142Z"
        stroke={color}
        fill={Colors.white}
        strokeWidth="0.8"
      />
    </g>
    <defs>
      <clipPath id="clip-shop-1">
        <rect width="34" height="34" fill={Colors.white} />
      </clipPath>
    </defs>
  </svg>
)
