module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/buildhome/repo/src/components/Layout/Layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PJ4K5R2","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.0.27","publicApiKey":"YjEzZTczOWQtNjA3NS00MThjLTlkNTUtZTJmNGZiNjQ4MTdmNjM3NTExNDcxNTMyMzAxNDUz","defaultLang":"it","currency":"eur","openCartOnAdd":true,"useSideCart":true,"locales":{"it":{"custom":{"phone_number_label":"Telefono"},"cart":{"view_detailed_cart":"Visualizza carrello dettagliato"},"address_form":{"province":"Regione"},"payment":{"form":{"deferred_payment_title":"Pagamento con bonifico","deferred_payment_instructions":"Effettuando questo ordine, l’utente accetta di pagare in seguito attraverso bonifico bancario."},"methods":{"deferred_payment":"Paga in seguito con bonifico"}}},"en":{"custom":{"phone_number_label":"Phone number"},"payment":{"form":{"deferred_payment_title":"Payment by bank transfer","deferred_payment_instructions":"By placing this order, you agree to pay later by bank transfer."},"methods":{"deferred_payment":"Pay later with bank transfer"}}}},"innerHTML":"\n          <billing section=\"bottom\">\n            <fieldset class=\"snipcart-form__set\">\n              <div class=\"snipcart-form__field\">\n                <snipcart-label class=\"snipcart__font--tiny\" for=\"phoneNumber\">\n                  {{ $localize('custom.phone_number_label') }}\n                </snipcart-label>\n                <snipcart-input name=\"phoneNumber\" required></snipcart-input>\n              </div>\n            </fieldset>\n          </billing> \n        "},
    }]
