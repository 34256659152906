import styled from 'styled-components'
import { Colors, Rem } from '../../commons/Theme'

export const StyledLayout = styled.div`
  width: 100%;
  overflow: hidden;
  // * {
  //   scrollbar-track {
  //     box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
  //     background-color: ${Colors.gray};
  //   }
  //
  //   scrollbar {
  //     width: ${Rem(3)};
  //     height: ${Rem(3)};
  //     background-color: ${Colors.black};
  //   }
  //
  //   scrollbar-thumb {
  //     background-color: ${Colors.yellow};
  //     border-radius: ${Rem(3)};
  //     width: ${Rem(3)};
  //     height: ${Rem(3)};
  //   }
  // }
  //
  // *::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
  //   background-color: ${Colors.gray};
  // }
  //
  // *::-webkit-scrollbar {
  //   width: ${Rem(3)};
  //   height: ${Rem(3)};
  //   background-color: ${Colors.black};
  // }
  //
  // *::-webkit-scrollbar-thumb {
  //   background-color: ${Colors.yellow};
  //   border-radius: ${Rem(3)};
  //   width: ${Rem(3)};
  //   height: ${Rem(3)};
  // }
`
