import React from 'react'

export default ({ color }) => (
  <svg viewBox="0 0 34 38">
    <path
      fill={color}
      d="M27,0.8H8.1h0c0,0-0.1,0-0.1,0h0h0l0,0l0,0.1l0,0l0-0.1c-0.1,0-0.1,0-0.2,0.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0
	l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0-0.1,0.1-0.1,0.1l0,0L0.8,7.9L0.8,8l0,0L0.8,7.9l0,0c0,0-0.1,0.1-0.1,0.1l0.1,0l0,0l-0.1,0l0,0
	l0,0l0,0c0,0,0,0,0,0l0,0c0,0,0,0.1-0.1,0.2l0,0v0v0c0,0,0,0.1,0,0.1v0v28.2h0.1v0l-0.1,0c0,0.2,0.1,0.3,0.2,0.4
	c0.1,0.1,0.3,0.2,0.4,0.2l0,0H27c0.3,0,0.6-0.3,0.6-0.6V1.5C27.6,1.1,27.4,0.8,27,0.8z M7.5,7.8H2.7L7.5,3V7.8z M8.1,8.9L8.1,8.9
	C8.5,9,8.8,8.7,8.8,8.4l-0.1,0l0,0l0.1,0v0V2.1h17.6v33.8H1.8V9L8.1,8.9L8.1,8.9L8.1,8.9L8.1,8.9z M0.7,8.1L0.7,8.1L0.7,8.1L0.7,8.1
	L0.7,8.1L0.7,8.1z M8,0.9L8,0.9L8,0.9L8,0.9z M33.4,9.9L33.4,9.9L33.4,9.9C33.4,9.9,33.4,9.9,33.4,9.9L33.4,9.9L33.4,9.9l-0.1,0
	L33.4,9.9L33,6.7h0c0-0.3-0.3-0.5-0.6-0.5h-1.9c-0.3,0-0.6,0.2-0.6,0.5l0,0v0c0,0,0,0,0,0l0,0l-0.5,3.1l0.1,0l0,0l-0.1,0l0,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0v0c0,0,0,0,0,0V27c0,0,0,0,0,0h0l0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0.1l0,0l0.1,0
	l0,0l0,0l-0.1,0c0,0,0,0,0,0.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0.1,0v0l-0.1,0c0,0,0,0,0,0l0,0l0.1,0l0,0l-0.1,0l1.4,4.2
	c0.1,0.3,0.3,0.4,0.6,0.4c0.3,0,0.5-0.2,0.6-0.4h0l1.4-4.1l0,0l-0.1,0l0,0l0,0l0.1,0c0,0,0,0,0,0h0l0,0l0,0v0l0,0l0,0l0,0
	c0,0,0,0,0-0.1l-0.1,0v0l0,0l0.1,0l0,0c0,0,0,0,0-0.1l0,0l0,0v0c0,0,0,0,0,0c0,0,0,0,0,0L33.4,9.9C33.4,9.9,33.4,9.9,33.4,9.9z
	 M33.3,9.9L33.3,9.9L33.3,9.9L33.3,9.9z M33.3,9.9L33.3,9.9L33.3,9.9L33.3,9.9z M31.9,27.7l-0.5,1.6l-0.5-1.6H31.9z M30.9,7.4h0.8
	l0.3,1.9h-1.4L30.9,7.4z M30.6,10.6h1.6v9.6h-1.6V10.6z M30.6,21.4h1.6v5h-1.6V21.4z M29.4,9.9L29.4,9.9L29.4,9.9L29.4,9.9z
	 M33.3,27.2L33.3,27.2L33.3,27.2L33.3,27.2z M32.9,6.7L32.9,6.7L32.9,6.7L32.9,6.7z M29.9,6.7L29.9,6.7L29.9,6.7L29.9,6.7z
	 M29.4,27.1L29.4,27.1L29.4,27.1L29.4,27.1z M31.9,31.4L31.9,31.4L31.9,31.4L31.9,31.4z M33.3,27.2L33.3,27.2L33.3,27.2L33.3,27.2z
	 M6,15.8c0-0.3,0.2-0.5,0.5-0.5h15.2c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5H6.5C6.2,16.4,6,16.1,6,15.8z M6,19.2
	c0-0.3,0.2-0.5,0.5-0.5h15.2c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5H6.5C6.2,19.7,6,19.5,6,19.2z M6.5,12h15.2
	c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5H6.5c-0.3,0-0.5-0.2-0.5-0.5C6,12.2,6.2,12,6.5,12z M13.1,5.7c0-0.3,0.2-0.5,0.5-0.5
	h8.1c0.3,0,0.5,0.2,0.5,0.5S22,6.3,21.7,6.3h-8.1C13.3,6.3,13.1,6,13.1,5.7z M8.5,27.1L8.5,27.1h-2c-0.3,0-0.6,0.3-0.6,0.6v4.6H6v0
	l-0.1,0c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2l0,0h2c0.3,0,0.6-0.3,0.6-0.6v-4.6v0l-0.1,0v0l0.1,0
	C9.2,27.3,8.9,27.1,8.5,27.1z M7.1,28.3h0.8v3.3H7.1V28.3z M13.4,24c-0.1-0.1-0.3-0.2-0.4-0.2h-2h0l0,0.1h0l0-0.1
	c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4l0,0v7.8c0,0.3,0.3,0.6,0.6,0.6h2l0,0c0.2,0,0.3-0.1,0.4-0.2
	c0.1-0.1,0.2-0.3,0.2-0.4l0,0v-7.8l0,0C13.6,24.3,13.5,24.1,13.4,24z M11.5,25.1h0.8v6.5h-0.8V25.1z M17.3,25.1h-2
	c-0.3,0-0.6,0.3-0.6,0.6v6.5c0,0.3,0.3,0.6,0.6,0.6h2c0.3,0,0.6-0.3,0.6-0.6v-6.5C17.9,25.4,17.7,25.1,17.3,25.1z M15.9,26.3h0.8
	v5.3h-0.8V26.3z M21.7,21.6h-2c-0.3,0-0.6,0.3-0.6,0.6v10l0,0c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2l0-0.1h0v0.1h2l0,0
	c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4l0,0v-10C22.3,21.9,22,21.6,21.7,21.6z M20.3,22.9h0.8v8.7h-0.8V22.9z"
    />
  </svg>
)
