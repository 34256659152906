import { createGlobalStyle } from 'styled-components'
import './fonts.css'
import './reset.css'
import 'swiper/swiper-bundle.css'
import { BP, Colors, Font, Rem } from './Theme'

export default createGlobalStyle`

    * {
      outline: none !important;
      box-sizing: border-box;
    }
    
    html {
        font-size: 100%;
        font-family: ${Font.sans};
        font-weight: bold;
        background-color: ${Colors.white};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        scroll-behavior: smooth;
    }
    
    body {
      position: relative;
      margin: 0;
      padding: 0;
      max-width: 100%;
      color: ${Colors.black};
      background-color: ${Colors.white};
      opacity: 1 !important;
      transition: opacity.4s ease-out .4s;
      overflow-x: hidden;
      padding-top: ${Rem(64)};

      &.theme-black {
        color: ${Colors.white};
        background-color: ${Colors.black};
      }
      
      &.theme-yellow {
        //color: ${Colors.white};
        //background-color: ${Colors.yellow};
      }
      
      &:not(.has-user) {
        overflow: hidden;
        max-height: 100%;
      }
      
      &.has-menu {
        overflow: hidden;
      }
      
      &.has-modal {
        overflow: hidden;
      }

      &.has-relative-header {
        padding-top: ${Rem(0)};
      }
      
      &.has-fixed-nav {
        padding-top: ${Rem(144)};
        @media(${BP.ipad}) {
          padding-top: ${Rem(84)};
        }
      }
    }
    
    .color-yellow {
      color: ${Colors.yellow};
    }
    .color-blue {
      color: #5CB3FF;
    }
    .color-white {
      color: ${Colors.white};
    }
    .color-black {
      color: ${Colors.black};
    }
    .text-underline {
      text-decoration: underline;
    }
    strong {
      font-weight: bold;
    }
    
    .grecaptcha-badge {
      z-index: 1;
    }

    /* emulate 70% width column with padding */
    .col-70 {
      @media (${BP.desktop}) {
        padding-left: 50px !important;
      }
      @media (${BP.large}) {
        padding-left: 150px !important;
      }
      @media (${BP.big}) {
        padding-left: 250px !important;
      }
    }
    
    /* emulate 70% width column with padding for all children */
    .col-70-children {
      & > * {
        @media (${BP.desktop}) {
          padding-left: 50px !important;
        }
        @media (${BP.large}) {
          padding-left: 150px !important;
        }
        @media (${BP.big}) {
          padding-left: 250px !important;
        }
      }
    }
`
